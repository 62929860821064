import { useState, useEffect, useRef, useCallback } from 'react';

// Components
import { IdealImage } from '@prompto-ui';
import MediaView from './tourComponents/MediaView';

// Helpers
import env from 'src/environment';
import { localizeUnitFieldValues } from 'src/helpers/vmUnit';
import {
  UniqueSellingPoint,
  INavigationItem,
  IUnit,
  NavigationItem
} from '@prompto-api';
import { AnimatePresence } from 'framer-motion';
import localizer from 'src/localization/localizer';
import { usePrevious } from '@prompto-helpers';
import isEqual from 'lodash.isequal';
import { isMobile } from 'react-device-detect';
import { useDataState } from 'src/store/DataStore';
import { fetchSettingsFromURL, removeQueryParams } from 'src/helpers/utils';

// Styling
import {
  Wrapper,
  FirstImageWrapper,
  MediaViewWrapper,
  UnderlayImage,
  NavigationPrompt,
  PromptIcon
} from './ProjectTourStyledComponents';

// Types
interface ProjectTourProps {
  firstImage: any;
  onOpenUnitPage: any;
  contentCollection: any;
  navigationItem: any;
  allUnitList: any;
  unitList: any;
  vaultId: string;
  projectId: string;
  hideAdditionalInfoSwitcher?: boolean;
  hideControls?: boolean;
  isUnitTour?: boolean;
  hidePrices: boolean;
}

export interface IDimensions {
  width: number;
  height: number;
}

// Project tour
const ProjectTour = (props: ProjectTourProps) => {
  const {
    firstImage,
    onOpenUnitPage,
    contentCollection,
    navigationItem,
    allUnitList,
    unitList,
    vaultId,
    projectId,
    hideAdditionalInfoSwitcher = false,
    hideControls = false,
    isUnitTour = false,
    hidePrices
  } = props;

  const [imageTourLoaded, setImageTourLoaded] = useState(true);
  const [imageDimensions, setImageDimensions] = useState<any>();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  // media view
  const [mediaViews, setMediaViews] = useState<any[]>([]);
  const [activeMediaView, setActiveMediaView] = useState<any>(null);
  const [currentMediaSpotList, setCurrentMediaSpotList] = useState<
    INavigationItem[]
  >([]);

  const [backgroundImage, setBackgroundImage] = useState<string>();
  const [nightMode, setNightMode] = useState(false);

  const [uspList, setUspList] = useState<any>(null);

  const [showNavigatePrompt, setShowNavigatePrompt] = useState(false);

  const [hideAdditionalInfo, setHideAdditionalInfo] = useState(
    isMobile || hideAdditionalInfoSwitcher
  );

  const [
    unitSpecificHighlightedPolygonId,
    setUnitSpecificHighlightedPolygonId
  ] = useState();

  // Data state
  const { DataState, DataStateDispatch } = useDataState();
  const { fetchedNavigationItems } = DataState;

  // Refs
  const mediaImageWrapperRef = useRef(null);

  const previousUnitList = usePrevious(unitList);

  // Collect all polygons
  // Fetch all the items assigned to polygons
  useEffect(() => {
    if (mediaViews && mediaViews.length > 0) {
      const fetchedNavigationItemsIds = fetchedNavigationItems.map(
        (item: any) => item.objectId
      );

      const itemsAssignedToPolygonsPromises = mediaViews.reduce(
        (result, view) => {
          const updResult = [...result];
          const navigationItems =
            view.navigationCollection?.vmNavigationItemList;
          if (navigationItems && navigationItems.length > 0) {
            navigationItems.forEach((item: any) => {
              if (
                item.navigationItemType === 'polygonSpot' &&
                item.assignedNavigationItemObjectId &&
                !fetchedNavigationItemsIds.includes(
                  item.assignedNavigationItemObjectId
                )
              ) {
                updResult.push(
                  NavigationItem.get(item.assignedNavigationItemObjectId)
                );
              }
            });
          }

          return updResult;
        },
        []
      );

      if (itemsAssignedToPolygonsPromises.length > 0) {
        Promise.all(itemsAssignedToPolygonsPromises)
          .then((result) => {
            const navigationItems = result
              .filter((x) => x.data?.navigationItem?.loaded)
              .map((res) => res.data.navigationItem);

            DataStateDispatch({
              type: 'setFetchedNavigationItems',
              payload: navigationItems
            });
          })
          .catch(() => {});
      }
    }
  }, [mediaViews, DataStateDispatch, fetchedNavigationItems]);

  // Check if current media view contains at least one unit.
  // If not, switch to the one that does.
  // This logic should be executed only if unitList has changed
  useEffect(() => {
    if (!activeMediaView) return;

    // unitList is always the same for the Tour in Unit page
    // so this check should be skipped
    if (isEqual(previousUnitList, unitList) && !isUnitTour) return;

    const unitIds = unitList.map((unit: IUnit) => unit.objectId);

    const { pmv } = fetchSettingsFromURL();

    const isValidMediaView =
      !isUnitTour || !pmv || (pmv && activeMediaView.objectId === pmv);

    const currentViewContainsFilteredUnitsSpots =
      isValidMediaView &&
      activeMediaView.navigationCollection?.vmNavigationItemList?.some(
        (item: INavigationItem) => unitIds.includes(item.value)
      );

    // Check if current item is assigned to any polygon
    // In a unit specific tour there is always only one unit, hence one unitId
    const navItemRelatedToUnits = fetchedNavigationItems.filter(
      (item: any) => item.value === unitIds[0]
    );

    const currentViewContainsPolygonsWithFilteredUnitsAssignedToThem =
      activeMediaView.objectId === pmv &&
      navItemRelatedToUnits.length > 0 &&
      navItemRelatedToUnits.some((unit: any) => {
        activeMediaView.navigationCollection?.vmNavigationItemList?.find(
          (item: INavigationItem) =>
            item.navigationItemType === 'polygonSpot' &&
            item.assignedNavigationItemObjectId === unit.objectId
        );
      });

    if (currentViewContainsPolygonsWithFilteredUnitsAssignedToThem) {
      setUnitSpecificHighlightedPolygonId(
        currentViewContainsPolygonsWithFilteredUnitsAssignedToThem.objectId
      );
    }

    const currentViewContainsFilteredUnits =
      currentViewContainsFilteredUnitsSpots ||
      currentViewContainsPolygonsWithFilteredUnitsAssignedToThem;

    if (!isUnitTour) {
      removeQueryParams([{ pmv: '' }]);
    }

    if (currentViewContainsFilteredUnits) return;

    const mediaViewsContainingFilteredUnits = mediaViews.filter(
      (mediaView: INavigationItem) => {
        const isValidMediaView = pmv ? mediaView.objectId === pmv : true;
        return (
          isValidMediaView &&
          mediaView.navigationCollection.vmNavigationItemList.some(
            (item: INavigationItem) =>
              unitIds.includes(item.value) ||
              (item.navigationItemType === 'polygonSpot' &&
                navItemRelatedToUnits.some(
                  (unit: any) =>
                    item.assignedNavigationItemObjectId === unit.objectId
                ))
          )
        );
      }
    );

    if (mediaViewsContainingFilteredUnits.length > 0) {
      const targetMediaView = mediaViewsContainingFilteredUnits[0];

      const currentViewContainsPolygonsWithFilteredUnitsAssignedToThem =
        navItemRelatedToUnits.length > 0 &&
        targetMediaView.navigationCollection?.vmNavigationItemList?.find(
          (item: INavigationItem) =>
            item.navigationItemType === 'polygonSpot' &&
            navItemRelatedToUnits.some(
              (unit: any) =>
                item.assignedNavigationItemObjectId === unit.objectId
            )
        );

      if (currentViewContainsPolygonsWithFilteredUnitsAssignedToThem) {
        setUnitSpecificHighlightedPolygonId(
          currentViewContainsPolygonsWithFilteredUnitsAssignedToThem.objectId
        );
      }

      setActiveMediaView(targetMediaView);
    }
  }, [
    mediaViews,
    unitList,
    previousUnitList,
    activeMediaView,
    isUnitTour,
    fetchedNavigationItems
  ]);

  // Check if units are presented in several media views
  useEffect(() => {
    const unitIds = unitList.map((unit: IUnit) => unit.objectId);

    const mediaViewsContainingFilteredUnits = mediaViews.filter(
      (mediaView: INavigationItem) => {
        return mediaView.navigationCollection.vmNavigationItemList.some(
          (item: INavigationItem) => unitIds.includes(item.value)
        );
      }
    );

    if (mediaViewsContainingFilteredUnits.length > 1) {
      setShowNavigatePrompt(true);
    }
  }, [mediaViews, unitList]);

  // Hide navigation prompt automatically
  useEffect(() => {
    if (showNavigatePrompt) {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setShowNavigatePrompt(false);
      }, 3000);
    }
  }, [showNavigatePrompt]);

  // Get all USPs
  useEffect(() => {
    if (!vaultId || !projectId) return;
    UniqueSellingPoint.getAll(vaultId, projectId).then((result: any) => {
      if (result) {
        const { data } = result;
        const { vmUniqueSellingPointList } = data;

        if (vmUniqueSellingPointList) {
          let newList = vmUniqueSellingPointList.filter(
            (x: any) => x.vmUniqueSellingPointState !== 'archived'
          );
          setUspList(newList);
        } else {
          setUspList([]);
        }
      }
    });
  }, [vaultId, projectId]);

  useEffect(() => {
    if (navigationItem && uspList && contentCollection) {
      // Add the contentItem to all mediaViews
      const contentItemList = contentCollection?.vmContentItemList;

      let mediaViewItemList =
        navigationItem?.navigationCollection?.vmNavigationItemList.filter(
          (item: INavigationItem) => item.navigationItemType === 'mediaView'
        );

      if (contentItemList && mediaViewItemList) {
        mediaViewItemList = mediaViewItemList.map((mediaView: any) => {
          const mediaViewContentItem = contentItemList.find(
            (x: any) => x.objectId === mediaView.value
          );

          let nightViewContentItem;
          if (mediaView.nightValue) {
            nightViewContentItem = contentItemList.find(
              (x: any) => x.objectId === mediaView.nightValue
            );
          }

          let spotItemList =
            mediaView.navigationCollection.vmNavigationItemList;
          if (spotItemList) {
            spotItemList = spotItemList
              .map((spot: INavigationItem) => {
                if (spot.navigationItemType === 'unitSpot') {
                  // Add the unit to all unit spots
                  const unitFound =
                    allUnitList?.length > 0
                      ? allUnitList.find((unit: any) => {
                          const localizedUnit = localizeUnitFieldValues(unit);
                          return localizedUnit.objectId === spot.value;
                        })
                      : null;

                  if (unitFound) {
                    return {
                      ...spot,
                      unitItem: {
                        ...unitFound
                      }
                    };
                  } else {
                    return null;
                  }
                } else if (spot.navigationItemType === 'navigationSpot') {
                  // Add the contentItem to all navigation spots
                  const spotMediaView = mediaViewItemList.find(
                    (x: any) => x.objectId === spot.value
                  );
                  const spotContentItem = contentItemList.find(
                    (x: any) => x.objectId === spotMediaView?.value
                  );

                  let spotNightContentItem;
                  if (spotMediaView?.nightValue) {
                    spotNightContentItem = contentItemList.find(
                      (x: any) => x.objectId === spotMediaView?.nightValue
                    );
                  }

                  let fullSpotContentUri;
                  const transform = 'q=100';
                  const spotContentUri = spotContentItem?.contentUri;
                  if (spotContentUri) {
                    fullSpotContentUri = `${
                      env().baseImageUrl
                    }/${transform}/${spotContentUri}`;
                  }

                  let fullSpotNightContentUri;
                  const spotNightContentUri = spotNightContentItem?.contentUri;
                  if (spotNightContentUri) {
                    fullSpotNightContentUri = `${
                      env().baseImageUrl
                    }/${transform}/${spotNightContentUri}`;
                  }

                  // If there is no media view for the spot or no content item we don't show the spot
                  // This should perhaps be cleaned up then
                  if (spotMediaView && spotContentItem) {
                    const newObject: any = {
                      ...spot,
                      contentItem: {
                        ...spotContentItem,
                        fullContentUri: fullSpotContentUri
                      }
                    };

                    if (spotNightContentItem) {
                      newObject.nightContentItem = {
                        ...spotNightContentItem,
                        fullContentUri: fullSpotNightContentUri
                      };
                    }

                    return newObject;
                  }

                  return null;
                } else if (spot.navigationItemType === 'uspSpot') {
                  if (uspList) {
                    const uspForSpot =
                      uspList &&
                      uspList.find((x: any) => x.objectId === spot.value);

                    if (uspForSpot) {
                      return {
                        ...spot,
                        usp: uspForSpot
                      };
                    } else {
                      return null;
                    }
                  }
                } else if (spot.navigationItemType === 'album360Spot') {
                  let spotContentItem = contentItemList.find(
                    (x: any) => x.objectId === spot?.value
                  );
                  const mediaViewForSpot = mediaViewItemList.find(
                    (view: INavigationItem) => view.objectId === spot.value
                  );
                  if (mediaViewForSpot) {
                    spotContentItem = contentItemList.find(
                      (x: any) => x.objectId === mediaViewForSpot?.value
                    );
                  }

                  if (spotContentItem) {
                    const newSpot: any = {
                      ...spot,
                      contentItem: spotContentItem
                    };

                    // to separate 360 image spots that have respective media views
                    // from those that do not
                    if (mediaViewForSpot) {
                      newSpot.hasMediaView = true;
                    }

                    return newSpot;
                  } else {
                    return null;
                  }
                }
                return spot;
              })
              .filter((item: any) => item !== null);
          }

          const newObject = {
            ...mediaView,
            contentItem: mediaViewContentItem,
            navigationCollection: {
              ...mediaView.navigationCollection,
              vmNavigationItemList: spotItemList
            }
          };

          if (nightViewContentItem) {
            newObject.nightContentItem = nightViewContentItem;
          }

          return newObject;
        });
      }

      setMediaViews(mediaViewItemList);

      setImageTourLoaded(true);
    }
  }, [
    allUnitList,
    uspList,
    contentCollection,
    navigationItem,
    firstImage,
    history
  ]);

  // set default active media view
  useEffect(() => {
    if (mediaViews?.length > 0 && !activeMediaView) {
      setActiveMediaView(mediaViews[0]);
    }
  }, [mediaViews, activeMediaView]);

  // ser spot list for current media view
  useEffect(() => {
    if (activeMediaView) {
      setCurrentMediaSpotList(
        activeMediaView?.navigationCollection?.vmNavigationItemList
      );
    }
  }, [activeMediaView]);

  useEffect(() => {
    let underlayImageUri = `${env().baseImageUrl}/q=50:h=600/${
      nightMode
        ? activeMediaView?.nightContentItem?.contentUri
        : activeMediaView?.contentItem?.contentUri
    }`;

    setBackgroundImage(underlayImageUri);
  }, [nightMode, activeMediaView]);

  // Handlers
  const onUpdateMediaView = useCallback(
    (spotClicked: any) => {
      mediaViews.forEach((view) => {
        if (view.objectId === spotClicked.value) {
          setActiveMediaView(view);
          setCurrentMediaSpotList(
            view.navigationCollection?.vmNavigationItemList
          );
        }
      });
    },
    [mediaViews]
  );

  const mediaView = activeMediaView ? (
    <MediaViewWrapper
      id={activeMediaView?.objectId}
      initial={{ opacity: 0 }}
      animate={activeMediaView ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.5 }}
      ref={mediaImageWrapperRef}
    >
      {backgroundImage && <UnderlayImage src={backgroundImage} />}
      <MediaView
        key={activeMediaView?.objectId}
        mediaView={activeMediaView}
        spotList={currentMediaSpotList}
        onUpdateMediaView={onUpdateMediaView}
        onOpenUnitPage={onOpenUnitPage}
        imageTransition={{}}
        onStartImmersion={() => {}}
        onStopImmersion={() => {}}
        hideTourControls={hideControls}
        unitList={unitList}
        navigateToStartView={() => {
          setActiveMediaView(mediaViews[0]);
          setCurrentMediaSpotList(
            mediaViews[0]?.navigationCollection?.vmNavigationItemList
          );
        }}
        hideAdditionalInfo={hideAdditionalInfo}
        setHideAdditionalInfo={setHideAdditionalInfo}
        hideAdditionalInfoSwitcher={hideAdditionalInfoSwitcher}
        isStartView={activeMediaView?.objectId === mediaViews[0]?.objectId}
        contentCollection={contentCollection}
        uspList={uspList}
        allUnitList={allUnitList}
        highlightedPolygon={unitSpecificHighlightedPolygonId}
        isUnitTour={isUnitTour}
        hidePrices={hidePrices}
        onToggleFullscreen={() => {
          setIsFullscreen((current) => {
            if (!current) {
              document.body.style.overflow = 'hidden';
              if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
              }
            }

            if (current) {
              document.body.style.overflow = 'auto';
              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
            }

            return !current;
          });
        }}
        isFullscreen={isFullscreen}
      />
    </MediaViewWrapper>
  ) : null;

  const firstImageComponent = (
    <FirstImageWrapper
      initial={{ opacity: 1 }}
      animate={activeMediaView ? { opacity: 0 } : { opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: 'easeOut', delay: 0.5 }}
      width={imageDimensions?.width}
      height={imageDimensions?.height}
    >
      <IdealImage
        parentType="project"
        key={firstImage?.objectId}
        contentUri={firstImage?.contentUri}
        fallbackUri={firstImage?.contentUri}
        imageSize={firstImage?.originalImageSize}
        containerSize={{
          width: window.innerWidth,
          height: window.innerHeight
        }}
        onLoad={(iWidth: number, iHeight: number) => {
          if (iWidth && iHeight) {
            setImageDimensions({ width: iWidth, height: iHeight });
          }
        }}
        mustFillParent={true}
        baseImageUrl={env().baseImageUrl}
      />
    </FirstImageWrapper>
  );

  const content = imageTourLoaded ? mediaView : firstImageComponent;
  const extraInfo = (
    <AnimatePresence>
      {showNavigatePrompt && !hideControls && (
        <NavigationPrompt
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <PromptIcon icon={['fas', 'eye']} size="1x" />
          {localizer.projectTour.navigateTourToSeeMoreUnits}
        </NavigationPrompt>
      )}
    </AnimatePresence>
  );

  return (
    <Wrapper id="tourContainer" isFullscreen={isFullscreen}>
      {content}
      {extraInfo}
    </Wrapper>
  );
};

export default ProjectTour;
