import {
  performDeleteCall,
  performGetCall,
  performPostCall,
  performPutCall
} from '../api-helpers';

export interface SearchMarketplaceProjectsParams {
  offset?: number;
  limit?: number;
  searchValue?: string;
}

export interface GetUnitsCountParams {
  marketplaceId: string;
  projectIdList: string[];
  unitStateList: string[];
}

export interface UnitToPublish {
  vaultId: string;
  projectId: string;
  unitId: string;
  state: 'active' | 'archived';
  marketplaceId: string;
}

export const Marketplace = {
  /**
   * Get a light version of a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_validateandgetmarketplaceprojectlight
   */
  validateAndGetMarketplaceProjectLight: (
    marketplaceId: string,
    vaultId: string,
    projectId: string
  ) => {
    return performGetCall(
      `marketplace/${marketplaceId}/getLightProject/vault/${vaultId}/project/${projectId}`
    );
  },

  /**
   * Get the first x resources from a projectx
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getmarketplaceprojectresources
   */
  getMarketplaceProjectResources: (
    marketplaceId: string,
    vaultId: string,
    projectId: string,
    amountOfImages: number
  ) => {
    return performGetCall(
      `marketplace/${marketplaceId}/getProjectResources/vault/${vaultId}/project/${projectId}`,
      { amountOfImages }
    );
  },

  /**
   * Get the project and units
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getmarketplaceprojectandunits
   */
  getMarketplaceProjectAndUnits: (
    marketplaceId: string,
    vaultId: string,
    projectId: string
  ) => {
    return performGetCall(
      `marketplace/${marketplaceId}/getProject/vault/${vaultId}/project/${projectId}`
    );
  },

  /**
   * Search marketplace projects
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_searchmarketplaceproject
   */
  searchProjects: (
    marketplaceId: string,
    params: SearchMarketplaceProjectsParams
  ) => performGetCall(`marketplace/${marketplaceId}/search/projects`, params),

  /**
   * Get project amount of units
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getprojectunitscount
   */
  getUnitCount: (params: GetUnitsCountParams) =>
    performPostCall(`marketplace/projects/unitCount`, params),

  /**
   * Get filters biggest and smallest values
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getmarketplaceedgevalues
   */
  getMarketplaceFiltersEdges: (marketplaceId: string) => {
    return performGetCall(`marketplace/${marketplaceId}/search/projects/edges`);
  },

  /**
   * Get recently published projects
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getlastpublishedmarketplaceprojects
   */
  getLastPublishedProjects: (marketplaceId: string, amountOfProjects: number) =>
    performGetCall(`/marketplace/${marketplaceId}/projects/lastpublished`, {
      amountOfProjects
    }),

  /**
   * Get projects in particular state of units with particular percentage
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getprojectswithunitspercentageinstatelist
   */
  getProjectsWithUnitsPercentage: (
    marketplaceId: string,
    percentageInStates: number,
    unitStateList: string[]
  ) =>
    performPostCall(
      `/marketplace/${marketplaceId}/projects/withUnitsPercentageInStateList`,
      { percentageInStates, unitStateList }
    ),

  /**
   * Get number of available projects per province
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getamountprojectsbyprovince
   */
  getProjectsPerProvince: (
    marketplaceId: string,
    params: {
      provinceList: string[];
      country: string;
    }
  ) =>
    performPostCall(
      `/marketplace/${marketplaceId}/projects/amountByProvince`,
      params
    ),

  /**
   * Publish units to marketplace project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_publishunitbulktomarketplace
   */
  publishUnitBulkToMarketplace: (
    marketplaceId: string,
    params: {
      publishUnitToMarketplaceParamsList: UnitToPublish[];
    },
    sessionToken: string
  ) =>
    performPostCall(
      `/marketplace/${marketplaceId}/publishUnitBulkToMarketplace`,
      params,
      sessionToken
    ),

  /**
   * Register a new marketplace user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_registermarketplaceuser
   */
  registerMarketplaceUser: (params: any) =>
    performPostCall(`/users/marketplace/registerMarketplaceUser`, params),

  /**
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_registermarketplaceuseroauth
   */
  registerMarketplaceUserOAuth: (params: any) =>
    performPostCall(`/users/marketplace/registerMarketplaceUserOauth`, params),

  /**
   * Create a search preference of an authenticated user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_create_27
   */
  createMarketplaceUserSearchPreference: (
    params: any,
    sessionToken: string
  ) => {
    return performPostCall(
      `marketplaceUserSearchPreference`,
      params,
      sessionToken
    );
  },

  /**
   * Update a search preference of an authenticated user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_update_24
   */
  updateMarketplaceUserSearchPreference: (
    objectId: string,
    params: any,
    sessionToken: string
  ) => {
    return performPutCall(
      `marketplaceUserSearchPreference/${objectId}`,
      params,
      sessionToken
    );
  },

  /**
   * Delete a search preference of an authenticated user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_delete_10
   */
  deleteMarketplaceUserSearchPreference: (
    objectId: string,
    sessionToken: string
  ) => {
    return performDeleteCall(
      `marketplaceUserSearchPreference/${objectId}`,
      sessionToken
    );
  },

  getMarketplaceUserSearchPreference: (
    objectId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `marketplaceUserSearchPreference/${objectId}`,
      {},
      sessionToken
    );
  },

  /**
   * Get all the search preferences of the authenticated user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_18
   */
  getAllMarketplaceUserSearchPreferences: (
    params: any,
    sessionToken: string
  ) => {
    return performGetCall(
      `marketplaceUserSearchPreference`,
      params,
      sessionToken
    );
  },

  /**
   * Get all the marketplace favourites for a user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getall_18
   */
  getAllMarketplaceProjectFavourites: (
    vaultId: string,
    marketplaceId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `marketplaceUserProjectFavourite`,
      {
        vaultId,
        marketplaceId,
        loadProjects: true
      },
      sessionToken
    );
  },

  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_create_27
   */
  createFavouriteProject: (params: any, sessionToken: string) => {
    return performPostCall(
      `marketplaceUserProjectFavourite`,
      params,
      sessionToken
    );
  },

  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_delete_10
   */
  deleteFavouriteProject: (objectId: string, sessionToken: string) => {
    return performDeleteCall(
      `marketplaceUserProjectFavourite/${objectId}`,
      sessionToken
    );
  },

  /**
   https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_create_29
   */
  createFavouriteUnit: (params: any, sessionToken: string) => {
    return performPostCall(
      `marketplaceUserUnitFavourite`,
      params,
      sessionToken
    );
  },

  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_delete_12
   */
  deleteFavouriteUnit: (objectId: string, sessionToken: string) => {
    return performDeleteCall(
      `marketplaceUserUnitFavourite/${objectId}`,
      sessionToken
    );
  },

  /**
   https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getall_20
   */
  getAllMarketplaceUnitFavourites: (
    vaultId: string,
    marketplaceId: string,
    sessionToken: string
  ) => {
    return performGetCall(
      `marketplaceUserUnitFavourite`,
      {
        vaultId,
        marketplaceId,
        loadUnits: true
      },
      sessionToken
    );
  }
};
