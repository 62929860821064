import { useCallback, memo } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropDown from './DropDown';
import FilterButtonContent from './FilterButtonContent';
import FilterMenuContent from './FilterMenuContent';

// Helpers
import { motion } from 'framer-motion';
import filtersConfig from 'src/configs/ProjectsFilters';

// Types
import { ProjectsFilterKeyType, IProjectFilter } from '../ProjectsFilters';

// Styling
import styled, { StyledProps } from 'styled-components';

const Wrapper = styled(motion.div)<StyledProps<{ focused?: boolean }>>`
  width: ${filtersConfig.uiSettings.filterButtonWidth}px;
  height: 48px;
  padding: 14px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.white};
  outline: 1px solid
    ${({ focused, theme }) => (focused ? theme.black : 'transparent')};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  width: 18px !important;
  color: ${({ theme }) => theme.gray50};
  margin-right: 8px;
`;

const ArrowIcon = styled(StyledIcon)`
  margin-right: 0;
  cursor: pointer;
`;

const CloseIcon = styled(StyledIcon)`
  margin: 0 3px 0 auto;
  cursor: pointer;
  flex-shrink: 0;
`;

interface FilterItemProps {
  filter: IProjectFilter;
  removeFilter: (filterKey: ProjectsFilterKeyType) => void;
  lang?: string;
}

const FilterItem = memo(({ filter, removeFilter }: FilterItemProps) => {
  const renderButton = useCallback(
    (props: any) => {
      const sharedButtons = (
        <>
          <CloseIcon
            icon={['fas', 'times-circle']}
            size="1x"
            onClick={(e) => {
              e.stopPropagation();
              removeFilter(filter.key);
            }}
          />
          <ArrowIcon
            icon={['far', props.focused ? 'chevron-up' : 'chevron-down']}
            size="1x"
          />
        </>
      );

      return (
        <Wrapper
          {...transitions}
          focused={props.focused}
          onClick={props.onToggleMenu}
        >
          <StyledIcon size="1x" icon={['far', filter.icon as any]} />
          <FilterButtonContent filterKey={filter.key}>
            {sharedButtons}
          </FilterButtonContent>
        </Wrapper>
      );
    },
    [filter]
  );

  const renderDropDownContent = useCallback(
    () => <FilterMenuContent filterKey={filter.key} />,
    [filter]
  );

  return (
    <DropDown
      initiallyOpened={filter.focused}
      renderButton={renderButton}
      renderDropDownContent={renderDropDownContent}
      fixedHeight={filter.key !== 'location'}
    />
  );
});

const transitions = {
  initial: { opacity: 0, x: -10 },
  animate: { opacity: 1, x: 0 }
};

export default FilterItem;
