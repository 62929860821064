import { useState, useEffect, useCallback, memo } from 'react';
import { createPortal } from 'react-dom';

// Components
import Page from '../contentItems/Page';
import MobilePage from '../contentItems/MobilePage';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { usePrevious } from '@prompto-helpers';
import useKeyPress from 'src/hooks/use-key-press';
import useResize from 'use-resize';
import { IContentItem } from '@/libs/prompto-api/src';
import { isMobile as isMobileDevice, isMobileOnly } from 'react-device-detect';

// Styles
import styled from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const PageCounter = styled.p`
  position: absolute;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  text-align: right;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  color: white;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  background-color: rgba(90, 90, 90, 0.2);
  width: 72px;
  height: 40px;

  ${forBiggerThan.mobile`
    width: 96px;
    height: 48px;
    font-size: 1rem;
  `}
`;

const AnimationWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

const PageSwiperWrapper = styled(motion.div)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3007;
`;

export interface PageSwiperProps {
  activePage: number;
  pages: IContentItem[] | null;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onClose: () => void;
  videoFallbackThumbnail: string;
  CustomNavigation: any;
  onPageLoaded: () => void;
  isActive?: boolean;
  show: boolean;
}

const wrap = (min: number, max: number, value: number) => {
  const range = max - min;
  return ((((value - min) % range) + range) % range) + min;
};

const PageSwiper = memo((props: PageSwiperProps) => {
  const {
    activePage,
    pages,
    onNextPage,
    onPreviousPage,
    onClose,
    videoFallbackThumbnail,
    CustomNavigation,
    onPageLoaded,
    isActive,
    show
  } = props;

  const [[page, direction], setPage] = useState([activePage, 0]);
  const [dragAxis, setDragAxis] = useState<'x' | 'y'>();
  const [isMobile, setIsMobileDevice] = useState(isMobileDevice);
  const [transitioning, setTransitioning] = useState(false);

  const previousActivePage = usePrevious(activePage);
  const nextKeyPressed = useKeyPress('ArrowRight', false, false);
  const previousKeyPressed = useKeyPress('ArrowLeft', false, false);
  const [isDragEnabled, setIsDragEnabled] = useState<any>(
    isMobileOnly ? false : 'x'
  );
  const size = useResize();

  // Only do this when activePage changes
  useEffect(() => {
    if (
      activePage !== previousActivePage &&
      activePage !== page % (pages?.length || 0)
    ) {
      setPage([activePage, 0]);
    }
  }, [activePage, pages, page, previousActivePage]);

  const pageIndex = wrap(0, pages?.length || 0, page);
  const paginate = useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
      onPageLoaded();
    },
    [page, onPageLoaded]
  );

  const goToNextPage = useCallback(() => {
    if (isMobile || activePage + 1 < (pages?.length || 0)) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
        paginate(1);
        onNextPage();
      }, 0);
    }
  }, [paginate, onNextPage, isMobile, activePage, pages]);

  const goToPreviousPage = useCallback(() => {
    if (isMobile || activePage > 0) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
        paginate(-1);
        onPreviousPage();
      }, 0);
    }
  }, [paginate, onPreviousPage, isMobile, activePage]);

  // Arrow key presses
  useEffect(() => {
    if (
      nextKeyPressed &&
      isActive &&
      pages &&
      pages[pageIndex]?.contentItemType !== 'image360'
    ) {
      goToNextPage();
    }
  }, [nextKeyPressed, goToNextPage, isActive, pageIndex, pages]);

  useEffect(() => {
    if (
      previousKeyPressed &&
      isActive &&
      pages &&
      pages[pageIndex]?.contentItemType !== 'image360'
    ) {
      goToPreviousPage();
    }
  }, [previousKeyPressed, goToPreviousPage, isActive, pageIndex, pages]);

  // Resize
  useEffect(() => {
    setIsMobileDevice(isMobileDevice);
  }, [size]);

  useEffect(() => {
    if (
      (pages && pages[pageIndex]?.contentItemType === 'image360') ||
      isMobileOnly
    ) {
      setIsDragEnabled(false);
    } else {
      setIsDragEnabled('x'); //true
    }
  }, [pageIndex, pages]);

  // UI
  let navigationControlsProps = {
    ...CustomNavigation.props,
    showUI: true,
    onNextPage: goToNextPage,
    onPreviousPage: goToPreviousPage,
    pageLength: pages?.length,
    activePage: pageIndex,
    onGridView: onClose
  };

  let navigationControls;
  if (CustomNavigation) {
    navigationControls = {
      ...CustomNavigation,
      props: navigationControlsProps
    };
  }

  if (!show) return null;

  return createPortal(
    <PageSwiperWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: show ? 1 : 0 }}
      transition={{ duration: 0.1 }}
      onClick={(e) => e.stopPropagation()}
    >
      <PageCounter>{`${pageIndex + 1} / ${pages?.length}`}</PageCounter>

      {/* Swiper */}
      {/* @ts-ignore */}
      <AnimatePresence initial={false} custom={direction}>
        <AnimationWrapper
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 }
          }}
          drag={isDragEnabled}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          dragElastic={0.5}
          dragDirectionLock={true}
          onDirectionLock={(axis) => setDragAxis(axis)}
          onDragEnd={(e, info) => {
            const { offset, velocity } = info;

            // Horizontal swipe
            if (dragAxis === 'x') {
              const horizontalSwipe = swipePower(offset.x, velocity.x);
              if (horizontalSwipe < -swipeConfidenceThreshold) {
                goToNextPage();
              } else if (horizontalSwipe > swipeConfidenceThreshold) {
                goToPreviousPage();
              }
            }
            // Vertical swipe
            else if (dragAxis === 'y') {
              const verticalSwipe = swipePower(offset.y, velocity.y);
              if (
                verticalSwipe < -swipeConfidenceThreshold ||
                verticalSwipe > swipeConfidenceThreshold
              ) {
                onClose();
              }
            }
          }}
          dragPropagation={true}
        >
          {isMobileOnly ? (
            <MobilePage
              {...props}
              pageIndex={pageIndex}
              data={(pages && pages[pageIndex]) || {}}
              allPages={pages}
              videoFallbackThumbnail={videoFallbackThumbnail}
              autoplay={true}
              onNextPage={goToNextPage}
              onPreviousPage={goToPreviousPage}
              setIsDragEnabled={setIsDragEnabled}
              isDragEnabled={isDragEnabled}
            />
          ) : (
            <Page
              {...props}
              key={pages && pages[activePage]?.objectId}
              onNextPage={goToNextPage}
              onPreviousPage={goToPreviousPage}
              data={(pages && pages[pageIndex]) || {}}
              allPages={pages}
              autoplay={true}
              transitioning={transitioning}
              setIsDragEnabled={setIsDragEnabled}
              isDragEnabled={isDragEnabled}
            />
          )}
        </AnimationWrapper>
      </AnimatePresence>

      {navigationControls}
    </PageSwiperWrapper>,
    document.body
  );
});

export default PageSwiper;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
