import cloneDeep from 'lodash.clonedeep';

export interface IInitialFilters {
  price: PriceOrSurface;
  surface: PriceOrSurface;
  location: ILocation;
  numberOfBedrooms: NumberOfBedroomsOrNumberOfRooms;
  numberOfBathrooms: NumberOfBedroomsOrNumberOfRooms;
}

export interface PriceOrSurface {
  limits: LimitsOrValues;
  values: LimitsOrValues;
  unit: string;
}

export interface LimitsOrValues {
  min: number;
  max: number;
}

export interface ILocation {
  place: string;
  limits: LimitsOrValues;
  values: number;
  unit: string;
  coords: {
    lat: number;
    lng: number;
  };
}

export interface NumberOfBedroomsOrNumberOfRooms {
  options?: number[] | null;
  values?: number[] | null;
  displayMax: number;
}

export const filterProjects = (
  projects: any[] | null,
  filterValues: IInitialFilters
) => {
  if (!projects) return [];
  if (!filterValues) return projects;

  const {
    location: { values: searchRadius, coords },
    price,
    surface,
    numberOfBedrooms,
    numberOfBathrooms
  } = filterValues;

  const locationMatches = (project: any) => {
    // if coordinates are not specified, d not filter by location
    if (!coords) return true;

    // As we limit search radius by 250 kilometers and accuracy doesn't have to be exact to the centimeter,
    // we can treat the surface of the earth as flat.
    // Calculate a conversion from degrees to kilometers at the latitude of the center point,
    // then the Pythagorean theorem (https://en.wikipedia.org/wiki/Pythagorean_theorem)
    // can be used to get the distance

    // ky - kilometers per degree for latitude
    // kx - kilometers per degree for longitude (depends on center point latitude)
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * coords.lat) / 180) * ky;
    const dx = Math.abs(coords.lng - +project.address.longitude) * kx;
    const dy = Math.abs(coords.lat - +project.address.latitude) * ky;

    return Math.sqrt(dx * dx + dy * dy) <= searchRadius;
  };

  const unitsDataMatches = (unitsData: any) => {
    if (!unitsData) return false;

    const isSurfaceFilterActive =
      surface.limits.min !== surface.values.min ||
      surface.limits.max !== surface.values.max;

    const isPriceFilterActive =
      price.limits.min !== price.values.min ||
      price.limits.max !== price.values.max;

    const isBedroomsFilterActive =
      numberOfBedrooms.values?.length !== numberOfBedrooms.options?.length;

    const isBathroomsFilterActive =
      numberOfBathrooms.values?.length !== numberOfBathrooms.options?.length;

    const surfaceMathes = isSurfaceFilterActive
      ? unitsData.surface.max >= surface.values.min &&
        unitsData.surface.min <= surface.values.max
      : true;

    const priceMatches = isPriceFilterActive
      ? unitsData.price.max >= price.values.min &&
        unitsData.price.min <= price.values.max
      : true;

    const bedroomsMatch = isBedroomsFilterActive
      ? unitsData.numberOfBedrooms.some((bedroomsNumber: number) =>
          numberOfBedrooms.values?.includes(bedroomsNumber)
        )
      : true;

    const bathroomsMatch = isBathroomsFilterActive
      ? unitsData.numberOfBathrooms.some((bathroomsNumber: number) =>
          numberOfBathrooms.values?.includes(bathroomsNumber)
        )
      : true;

    return surfaceMathes && priceMatches && bedroomsMatch && bathroomsMatch;
  };

  const projectMatchesFilters = (project: any) =>
    locationMatches(project) && unitsDataMatches(project.unitsData);

  return projects.filter(projectMatchesFilters);
};

export const prepareSearchProjectsRequestBody = (
  page: number,
  pageSize: number,
  filterValues: any,
  selectedFilters: any,
  userLocation: {
    latitude: number;
    longitude: number;
  }
) => {
  const searchProjectsRequestBody: any = {
    offset: 0, //page * pageSize,
    limit: 200 //page * pageSize + pageSize
  };

  const locationFilter = selectedFilters.find((x: any) => x.key === 'location');
  const priceFilter = selectedFilters.find((x: any) => x.key === 'price');
  const surfaceFilter = selectedFilters.find((x: any) => x.key === 'surface');
  const bedroomsFilter = selectedFilters.find(
    (x: any) => x.key === 'numberOfBedrooms'
  );
  const bathroomsFilter = selectedFilters.find(
    (x: any) => x.key === 'numberOfBathrooms'
  );

  // userLongitude + userLatitude
  if (userLocation?.longitude && userLocation?.latitude) {
    searchProjectsRequestBody.userLongitude = userLocation.longitude;
    searchProjectsRequestBody.userLatitude = userLocation.latitude;
  }

  // longitute + latitude + radius
  if (locationFilter?.selected) {
    if (filterValues.location.searchByProvince) {
      searchProjectsRequestBody.provinceList =
        filterValues.location.provinceValues;
    } else if (filterValues.location.coords) {
      searchProjectsRequestBody.longitude = filterValues.location.coords.lng;
      searchProjectsRequestBody.latitude = filterValues.location.coords.lat;
      searchProjectsRequestBody.radius = filterValues.location.values;
    }
  }

  if (priceFilter?.selected) {
    // minPrice
    if (filterValues.price.limits.min !== filterValues.price.values.min) {
      searchProjectsRequestBody.minPrice = filterValues.price.values.min;
    }

    // maxPrice
    if (filterValues.price.limits.max !== filterValues.price.values.max) {
      searchProjectsRequestBody.maxPrice = filterValues.price.values.max;
    }
  }

  if (surfaceFilter?.selected) {
    // minSurface
    if (filterValues.surface.limits.min !== filterValues.surface.values.min) {
      searchProjectsRequestBody.minSurface = filterValues.surface.values.min;
    }

    // maxSurface
    if (filterValues.surface.limits.max !== filterValues.surface.values.max) {
      searchProjectsRequestBody.maxSurface = filterValues.surface.values.max;
    }
  }

  // minBedrooms + maxBedrooms
  if (bedroomsFilter?.selected) {
    if (
      filterValues.numberOfBedrooms.values.length > 1 &&
      filterValues.numberOfBedrooms.values.length !==
        filterValues.numberOfBedrooms.options.length
    ) {
      const minBedrooms = Math.min(...filterValues.numberOfBedrooms.values);
      const maxBedrooms = Math.min(
        filterValues.numberOfBedrooms.displayMax,
        Math.max(...filterValues.numberOfBedrooms.values)
      );

      searchProjectsRequestBody.minBedrooms = minBedrooms;
      searchProjectsRequestBody.maxBedrooms = maxBedrooms;
    } else if (filterValues.numberOfBedrooms.values.length === 1) {
      const chosenNumOfBedrooms = filterValues.numberOfBedrooms.values[0];
      searchProjectsRequestBody.minBedrooms = chosenNumOfBedrooms;
      searchProjectsRequestBody.maxBedrooms = chosenNumOfBedrooms;
    }
  }
  if (bathroomsFilter?.selected) {
    // minBathrooms + maxBathrooms
    if (
      filterValues.numberOfBathrooms.values.length > 1 &&
      filterValues.numberOfBathrooms.values.length !==
        filterValues.numberOfBathrooms.options.length
    ) {
      const minBathrooms = Math.min(...filterValues.numberOfBathrooms.values);
      const maxBathrooms = Math.min(
        filterValues.numberOfBathrooms.displayMax,
        Math.max(...filterValues.numberOfBathrooms.values)
      );

      searchProjectsRequestBody.minBathrooms = minBathrooms;
      searchProjectsRequestBody.maxBathrooms = maxBathrooms;
    } else if (filterValues.numberOfBathrooms.values.length === 1) {
      const chosenNumOfBathrooms = filterValues.numberOfBathrooms.values[0];
      searchProjectsRequestBody.minBathrooms = chosenNumOfBathrooms;
      searchProjectsRequestBody.maxBathrooms = chosenNumOfBathrooms;
    }
  }

  return searchProjectsRequestBody;
};

export const getUpdatedInitialFilters = (
  initialFilters: IInitialFilters,
  data: any
) => {
  const tempInitialFilters = initialFilters;
  // set new values from backend, but also provide fallback if some values will not be provided
  tempInitialFilters.price.limits.max =
    data?.maxPrice || tempInitialFilters.price.limits.max;
  tempInitialFilters.price.limits.min =
    data?.minPrice || tempInitialFilters.price.limits.min;
  tempInitialFilters.price.values.max =
    data?.maxPrice || tempInitialFilters.price.values.max;
  tempInitialFilters.price.values.min =
    data?.minPrice || tempInitialFilters.price.values.min;
  tempInitialFilters.surface.limits.min =
    data?.minSurface || tempInitialFilters.surface.limits.min;
  tempInitialFilters.surface.limits.max =
    data?.maxSurface || tempInitialFilters.surface.limits.max;
  tempInitialFilters.surface.values.min =
    data?.minSurface || tempInitialFilters.surface.values.min;
  tempInitialFilters.surface.values.max =
    data?.maxSurface || tempInitialFilters.surface.values.max;
  return tempInitialFilters;
};

export const normalizeFilters = (
  oldFilters: { filterValues: IInitialFilters },
  newFilters: IInitialFilters
) => {
  const filtersClone = cloneDeep(oldFilters);
  // here we just check, that previous saved filters are feet new filters limits
  // only for price and surface
  ['price', 'surface'].forEach((filterProperty) => {
    // values - current values applied to filters
    // limits - current max/min values applied to filters (should be provided by backend
    // and based on min/max values from data base related to units within current marketplace)
    // provide shortcuts for better readability
    //@ts-ignore
    const valueMaxPrev = filtersClone.filterValues[filterProperty].values
      .max as number; //@ts-ignore
    const limitMaxNew = newFilters[filterProperty].limits.max as number;
    //@ts-ignore
    const valueMinPrev = filtersClone.filterValues[filterProperty].values
      .min as number; //@ts-ignore
    const limitMinNew = newFilters[filterProperty].limits.min as number;
    // set new limits
    //@ts-ignore
    filtersClone.filterValues[filterProperty].limits.min = limitMinNew;
    //@ts-ignore
    filtersClone.filterValues[filterProperty].limits.max = limitMaxNew;
    // if max value is greater than max limit -> set max limit as max value
    if (valueMaxPrev > limitMaxNew) {
      //@ts-ignore
      filtersClone.filterValues[filterProperty].values.max = limitMaxNew;
    }
    // if max value is less than min limit -> set min limit as max value
    if (valueMaxPrev < limitMinNew) {
      //@ts-ignore
      filtersClone.filterValues[filterProperty].values.max = limitMinNew;
    }
    // if min value is less than min limit -> set min limit as min value
    if (valueMinPrev < limitMinNew) {
      //@ts-ignore
      filtersClone.filterValues[filterProperty].values.min = limitMinNew;
    }
    // if min value is greater than max limit -> set max limit as min value
    if (valueMinPrev > limitMaxNew) {
      //@ts-ignore
      filtersClone.filterValues[filterProperty].values.min = limitMaxNew;
    }
  });
  return filtersClone;
};

export const isValidProjectsPageUrl = (pathname: string) => {
  return (
    (pathname.includes('projects') ||
      pathname.includes('nieuwbouw') ||
      pathname.includes('developer') ||
      pathname.includes('ontwikkelaar')) &&
    !pathname.includes('developers')
  );
};

export const getFiltersOrLocationFromUrl = (pathname: string) => {
  if (pathname.includes(`nieuwbouw`)) {
    return getMapLocationFromUrl(pathname);
  }
  if (pathname.includes(`developer`) || pathname.includes('ontwikkelaar')) {
    return { developer: getVaultFromDeveloperUrl(pathname) };
  }
};

export const getMapLocationFromUrl = (pathname: string) => {
  const paths = pathname.split('/');
  const location = paths[2];

  switch (location) {
    case 'kust':
      return { lat: 51.19784721572591, lng: 2.945348838671018, zoom: 10 };
    case 'west-vlaanderen':
      return { lat: 51.08799645273689, lng: 3.059415465820323, zoom: 10 };
    case 'oost-vlaanderen':
      return { lat: 51.015481292794945, lng: 3.719968444335948, zoom: 10 };
    case 'koksijde':
      return { lat: 51.09798727905462, lng: 2.659908966442188, zoom: 13 };
    case 'nieuwpoort':
      return { lat: 51.13897038025809, lng: 2.73964697895249, zoom: 13 };
    case 'oostende':
      return { lat: 51.2259149141538, lng: 2.924869604684912, zoom: 13 };
    case 'blankenberge':
      return { lat: 51.310935864777036, lng: 3.138706016485928, zoom: 13 };
    case 'roeselare':
      return { lat: 50.941230083689085, lng: 3.133573180664073, zoom: 12 };
    case 'waregem':
      return { lat: 50.89582920887703, lng: 3.4361694460558345, zoom: 12 };
    case 'oudenaarde':
      return { lat: 50.85011307472281, lng: 3.608071057417246, zoom: 13 };
    case 'ronse':
      return { lat: 50.7413050390858, lng: 3.6017317156490414, zoom: 13 };
    case 'turnhout':
      return { lat: 51.317731039004535, lng: 4.944022120299967, zoom: 13 };
    case 'gent':
      return { lat: 51.067977129263134, lng: 3.760947367324361, zoom: 12 };
    case 'antwerpen':
      return { lat: 51.190679914599166, lng: 4.398154398574361, zoom: 11 };
    case 'veurne':
      return { lat: 51.07043965284281, lng: 2.6692671245365496, zoom: 14 };
    case 'brussel':
      return { lat: 50.84745549372822, lng: 4.375304256979695, zoom: 11 };
    case 'knokke-heist':
      return { lat: 51.33988299304806, lng: 3.267096044019957, zoom: 13 };
  }
};

export const getVaultFromDeveloperUrl = (pathname: string) => {
  const paths = pathname.split('/');
  const developer = paths[2];

  if (developer === 'ion' || developer === 'dtyVOxtbyo') return 'dtyVOxtbyo';
  if (developer === 'wedevelop' || developer === 'W6LzYjOmqG')
    return 'W6LzYjOmqG';
  if (developer === 'corbeau' || developer === 'fewWlu5Spp')
    return 'fewWlu5Spp';
  if (developer === 'hyboma' || developer === '7VbEm2sP5J') return '7VbEm2sP5J';
  if (developer === 'atland' || developer === 'hmDf618Rp0') return 'hmDf618Rp0';
  if (developer === 'groepschollier' || developer === 'MofU1aqwok')
    return 'MofU1aqwok';
  if (developer === 'fjord' || developer === 'NYSonuA6Yh') return 'NYSonuA6Yh';
  if (developer === 'revive' || developer === 'dlmwNKT3bU') return 'dlmwNKT3bU';
  if (developer === 'oryxprojects' || developer === 'i0S84r7Yr9')
    return 'i0S84r7Yr9';
  if (developer === 'bcabouwtbeter' || developer === 'dTH7LYljW1')
    return 'dTH7LYljW1';
  if (developer === 'montis' || developer === 'jH1jCwUgNS') return 'jH1jCwUgNS';
  if (developer === 'christiaensprojects' || developer === 'FuHgXfahN2')
    return 'FuHgXfahN2';
  if (developer === 'pocrealestate' || developer === 'A6VFGdehFH')
    return 'A6VFGdehFH';
  if (developer === 'bekamiprojects' || developer === 'vbd1dLPQLP')
    return 'vbd1dLPQLP';
  if (developer === 'ascot' || developer === 'jFm3NOCEIH') return 'jFm3NOCEIH';
  if (developer === 'vesta' || developer === 'bcht8YfuAQ') return 'bcht8YfuAQ';
  if (developer === 'unibricks' || developer === 'fAIzQj0RZp')
    return 'fAIzQj0RZp';
  if (developer === 'galika' || developer === 'XpBIK3RamO') return 'XpBIK3RamO';
  if (developer === 'alides' || developer === 'csPEAk28Pb') return 'csPEAk28Pb';
  if (developer === 'alysee' || developer === '0TRpND7DXg') return '0TRpND7DXg';
  if (developer === 'janssenb' || developer === 'm5Js3C6CpY')
    return 'm5Js3C6CpY';
  if (developer === 'steenoven' || developer === '1DMEvtrfIz')
    return '1DMEvtrfIz';
  if (developer === 'immobel' || developer === 'lMOWeFa7Rg')
    return 'lMOWeFa7Rg';
  if (developer === 'yobo' || developer === 'ByhGISWigg') return 'ByhGISWigg';
  if (developer === 'karolienlafaut' || developer === 'qSEYBzLYAS')
    return 'qSEYBzLYAS';
  if (developer === 'intrabouw' || developer === 'aLuCXEJevI')
    return 'aLuCXEJevI';
  if (developer === 'ablimmo' || developer === 'Mpts373KsR')
    return 'Mpts373KsR';
  return '';
};
