import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdditionalInformationBlock from 'src/components/additionalInformationBlock/AdditionalInformationBlock';
import GetInTouchCard from 'src/components/getInTouchForm/GetInTouchCard';
import InformationBadge, {
  BadgeColor
} from 'src/components/informationBadge/InformationBadge';
import Resources from 'src/components/resources/Resources';
import { RichTextRenderer } from '@prompto-ui';
import UnitList from 'src/components/unitList/UnitList';

// Helpers
import currencySymbolMap from 'currency-symbol-map';
import {
  IContentItem,
  IUnit,
  Tracking,
  TrackMarketplaceUnitVisitorParams,
  TrackGetInTouchBasicParams,
  GeneralFieldType,
  IProject
} from '@/libs/prompto-api/src';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';
import localizer, {
  getCurrentLanguage,
  getValueFromLocalized
} from 'src/localization/localizer';
import { useAppState } from 'src/store/AppStore';
import { useDataState } from 'src/store/DataStore';
import { isMobileOnly } from 'react-device-detect';
import { useContactUsState } from 'src/store/ContactUsStore';
import { sortBySortingOrders } from 'src/helpers/vmUnit';
import { getCurrentProject } from 'src/helpers/vmProjectSection';

// Styling
import styled, { StyledProps, css } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { getLocalizedTextForUnitState } from 'src/components/unitList/unitListHelpers';
import { useMobileOrientation } from 'src/hooks/use-mobile-orientation';
import { motion } from 'framer-motion';
import { checkUnitFieldsVisibility } from 'src/helpers/vmUnit';
import ShareButton from 'src/components/ShareButton';
import ContactUsButton from 'src/components/ContactUsButton';

const navBarHeight = 72;
const backToProjectBarHeight = 70;

const PageWrapper = styled(motion.div)<StyledProps<{ isModal: boolean }>>`
  display: flex;
  flex-flow: column;
  background-color: ${({ theme }) => theme.white};

  ${({ isModal }) => {
    if (isModal) {
      return css`
        position: fixed;
        width: 100%;
        top: calc(${navBarHeight}px + ${backToProjectBarHeight}px);
        z-index: 3007;
        overflow: auto;
        height: calc(100% - ${navBarHeight}px - ${backToProjectBarHeight}px);
        left: 50%;
        transform: translateX(-50%);

        ${forBiggerThan.tablet`
        width: 90%;
    `}
      `;
    }
  }};
`;

const BackdropOverlay = styled.div<StyledProps<{ isModal: boolean }>>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(46, 49, 56, 0.3);
  z-index: 3007;
  pointer-events: ${({ isModal }) => (isModal ? 'all' : 'none')};
`;

export const TopBarWrapper = styled(motion.div)<
  StyledProps<{ isModal?: boolean }>
>`
  display: flex;
  flex-flow: row;
  background-color: ${({ theme }) => theme.beigeBg10};
  padding: 16px;
  height: ${backToProjectBarHeight}px;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  display: flex;

  ${forBiggerThan.tablet`
    padding: 16px 50px;
  `}

  ${({ isModal }) => {
    if (isModal) {
      return css`
        width: 100%;
        position: fixed;
        z-index: 3007;
        left: 50%;
        transform: translateX(-50%);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        ${forBiggerThan.tablet`
        width: 90%;
    `}
      `;
    }
  }};
`;

const GoBackButton = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const BackIcon = styled(FontAwesomeIcon)`
  padding: 10px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.beigeBg20};
  margin-right: 16px;
  height: 20px;
  min-width: 20px;
`;

const BackText = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: bold;
  display: none;

  ${forBiggerThan.tablet`
  display: block;    `}
`;

const MainUnitInfoSection = styled.div`
  display: grid;
  margin: 8px 16px 0px;
  grid-template-columns: 1fr;

  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas:
    'Title'
    'Badges'
    'UnitInfo'
    'Form'
    'UnitDescription'
    'Tour';

  ${forBiggerThan.tablet`
  margin: 24px 50px 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto ;

    grid-template-areas:
      'Title Form'
      'Badges Form'
      'UnitInfo Form'
      'UnitDescription UnitDescription'
      'Tour .';
    `}

  ${forBiggerThan.laptop`
  margin: 24px 50px 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    grid-template-areas:
      'Title Form'
      'Badges Form'
      'UnitInfo Form'
      'UnitDescription UnitDescription'
      'Tour .';
  `}

  ${forBiggerThan.desktop`
    margin: 64px 66px 0px;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto auto auto;

    grid-template-areas:
      'Title Badges Form'
      'UnitInfo UnitInfo Form'
      'UnitDescription UnitDescription Form'
      'Tour Tour .';
  `}

${forBiggerThan.largeDesktop`
    margin: 64px 116px 0px;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto auto auto;

    grid-template-areas:
      'Title Badges Form'
      'UnitInfo UnitInfo Form'
      'UnitDescription UnitDescription Form'
      'Tour Tour .';
  `}
`;

const UnitTitleAndProject = styled.div`
  grid-area: Title;
  display: flex;
  flex-flow: column;
`;

const UnitTitle = styled.div`
  width: 100%;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2.75rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};

  margin-top: 16px;

  ${forBiggerThan.desktop`
  margin-top: 0;
  margin-right: 32px;
  `}

  ${forBiggerThan.largeDesktop`
  max-width: 400px;
  `}
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-flox: row;
  align-items: center;
`;
const ProjectIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.gray50};
  margin-right: 8px;
`;
const ProjectName = styled.div`
  font-size: 1.125rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.black};
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-flow: row;
  grid-area: Badges;
  margin-top: 16px;
  gap: 8px;

  ${forBiggerThan.tablet`
  gap: 14px;
  flex-wrap: wrap;
  justify-content: flex-start;
  `}

  ${forBiggerThan.desktop`
  justify-content: flex-end !important;
  margin-top: 0;
  flex-wrap: nowrap;
  `}
`;

const StatusBadge = styled(InformationBadge)`
  font-size: 0.75rem;

  ${forBiggerThan.tablet`
  font-size: 1rem;
  `}
`;

const PriceBadge = styled(InformationBadge)`
  font-size: 1.5rem;

  ${forBiggerThan.tablet`
  font-size: 1.875rem;
  `}
`;

const UnitAdditionalInformation = styled(AdditionalInformationBlock)`
  grid-area: UnitInfo;
  margin-top: 32px;
  margin-bottom: 32px;

  ${forBiggerThan.tablet`
  margin-top: 48px;
  `}
`;

const UnitDescription = styled.div`
  margin-top: 12px;
  grid-area: UnitDescription;
`;

const UnitDescriptionLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.gray60};
  margin-bottom: 24px;
`;

const UnitDescriptionContent = styled.div``;

const GetInTouchCTA = styled(GetInTouchCard)`
  grid-area: Form;
  margin: 0;
  margin-left: auto;
  margin-right: auto;

  ${forBiggerThan.tablet`
  margin-left: 50px !important;
  `}

  ${forBiggerThan.desktop`
  margin-left: 184px;
  `}
`;

const ResourcesWrapper = styled.div`
  width: 100%;
  margin-top: 24px;

  ${forBiggerThan.tablet`
  margin-top: 0px;
  `}
`;

const InfoIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.gray50};
`;

const UnitListWrapper = styled.div`
  margin-top: 36px;

  ${forBiggerThan.tablet`
  margin-top: 82px;
  `}
`;

const TourContentWrapper = styled.div`
  grid-area: Tour;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

const TourTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.gray60};
  margin-top: 24px;
  margin-bottom: 6px;

  ${forBiggerThan.tablet`
  margin-bottom: 12px;
  `}
`;

const TourContainer = styled.div<StyledProps<{ isLandscape: boolean }>>`
  max-width: 880px;
  max-height: 300px;
  position: relative;
  width: 100%;
  aspect-ratio: ${({ isLandscape }) =>
    !isMobileOnly ? '16 / 6.5' : isLandscape ? '12 / 5' : '9 / 12'};
  overflow: hidden;
  flex-shrink: 0;

  ${forBiggerThan.tablet`
  max-height: 800px;
  `}
`;

const TourOverviewMessage = styled.div`
  margin-top: 12px;
  user-select: none;
  cursor: pointer;
`;

const HeaderButtonsWrapper = styled(motion.div)`
  margin: 0 0 0 auto;
  display: flex;
  column-gap: 16px;
`;

const RequestPriceButton = styled.div`
  width: max-content;
  max-height: 30px;
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: 24px;
  background-color: ${({ theme }) => theme.beigeBg20};
  padding: 8px 16px;
  color: ${({ theme }) => theme.black};
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  user-select: none;
`;

const RequestPriceIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  margin-left: 8px;
`;

export interface UnitDetailPageProps {
  returnToProjectPage: (scrollToImageTour?: boolean) => void;
  unitList: Array<IUnit>;
  companyLogoUri: string;
  projectTitle: string;
  projectThumbnail: IContentItem;
  projectAddress: string;
  renderUnitTour: any;
  isModal?: boolean;
  vaultId: string;
  projectId: string;
  marketplaceId?: string;
}

const UnitDetailPage = ({
  returnToProjectPage,
  unitList,
  companyLogoUri,
  projectTitle,
  renderUnitTour: RenderUnitTour = null,
  vaultId,
  projectId,
  marketplaceId,
  isModal = true
}: UnitDetailPageProps) => {
  const { unitId } = useParams();

  const [thisUnit, setThisUnit] = useState<any>(null);
  const [thisProject, setThisProject] = useState<IProject | null>(null);

  const [contentItemList, setContentItemList] = useState<IContentItem[]>([]);
  const [previewContent, setPreviewContent] = useState<IContentItem[]>([]);

  const [basicTrackingData, setBasicTrackingData] =
    useState<TrackGetInTouchBasicParams>();
  const [initiateTransferToProject, setInitiateTransferToProject] =
    useState<boolean>(false);
  const [
    scrollToUnitsAfterTransferToProject,
    setScrollToUnitsAfterTransferToProject
  ] = useState<boolean>(false);

  // App state
  const { AppState } = useAppState();
  const { sessionId, visitorId } = AppState;

  const { DataState, DataStateDispatch } = useDataState();
  const { scrollToTopOfUnitPage, projects, contentCollections } = DataState;

  /**
   * START of logic to hide prices based on url param and to show contact us form
   */

  const { ContactUsStateDispatch } = useContactUsState();

  const requestUnitPrice = () => {
    ContactUsStateDispatch({
      type: 'setShowContactUsForm',
      payload: {
        showContactUsForm: true,
        unitObjectId: unitId,
        location: 'unitPagePrice'
      }
    });
  };

  const requestUnitPriceButton = (
    <RequestPriceButton
      onClick={(e) => {
        e.stopPropagation();
        requestUnitPrice();
      }}
    >
      {localizer.unitList.priceOnRequest}
      <RequestPriceIcon icon={['far', 'arrow-right']} size="1x" />
    </RequestPriceButton>
  );
  /**
   * END of logic to hide prices
   */

  const navigate = useNavigate();

  // If there is no such unit in the list, navigate back to project details page
  useEffect(() => {
    if (unitId && vaultId && projectId) {
      const thisUnit = unitList.find((x) => x.objectId === unitId);
      if (thisUnit) {
        setThisUnit(thisUnit);
      } else {
        navigate(
          `/vault/${vaultId}/project/${projectId}${window.location.search}`
        );
      }
    }
  }, [unitId, unitList, vaultId, projectId]);

  useEffect(() => {
    const thisProject = getCurrentProject(projects, projectId);
    setThisProject(thisProject ?? null);
  }, [projectId, projects]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  // Tracking
  useEffect(() => {
    if (sessionId && visitorId && projectId && unitId && vaultId) {
      const trackUnitVisitorParams: TrackMarketplaceUnitVisitorParams = {
        visitorId: visitorId,
        sessionId: sessionId,
        projectObjectId: projectId,
        vaultObjectId: vaultId,
        unitObjectId: unitId
      };

      setBasicTrackingData(
        trackUnitVisitorParams as TrackGetInTouchBasicParams
      );

      Tracking.trackMarketplaceUnitVisitor(trackUnitVisitorParams).catch(
        () => {}
      );
    }
  }, [sessionId, visitorId, projectId, unitId, vaultId]);

  useEffect(() => {
    if (scrollToTopOfUnitPage) {
      const scrollIntoViewOptions = {
        top: -10000,
        behavior: 'smooth' as ScrollBehavior
      };
      document.getElementById('unitPage')?.scrollBy(scrollIntoViewOptions);
      DataStateDispatch({
        type: 'setScrollToTopOfUnitPage',
        payload: false
      });
    }
  }, [scrollToTopOfUnitPage]);

  const [showPrice, showBedrooms, showBathrooms, showSurface] =
    checkUnitFieldsVisibility(thisUnit, [
      GeneralFieldType.price,
      GeneralFieldType.numberOfBedrooms,
      GeneralFieldType.numberOfBathrooms,
      GeneralFieldType.surface
    ]);

  useEffect(() => {
    if (thisUnit && thisProject) {
      // Get loaded collections
      const loadedCollection = contentCollections.find(
        (x: any) => x.objectId === thisUnit.vmContentCollection?.objectId
      );
      const loadedLinkedCollection = contentCollections.find(
        (x: any) => x.objectId === thisUnit.linkedContentCollection?.objectId
      );

      const unitCollection = [
        ...(loadedCollection?.vmContentItemList ?? []),
        ...(loadedLinkedCollection?.vmContentItemList.map(
          (item: IContentItem) => ({
            ...item,
            isLinked: true
          })
        ) ?? [])
      ];

      if (unitCollection.length > 0) {
        const contentItemList = thisUnit
          ? (sortBySortingOrders(
              unitCollection,
              thisUnit?.contentItemCustomSortingValues ?? [],
              thisProject?.defaultContentItemSorting
            ) as IContentItem[])
          : [];

        const previewContent = contentItemList.filter((item) => {
          return (
            item.contentItemType === 'image' || item.contentItemType === 'video'
          );
        });

        setContentItemList(contentItemList);
        setPreviewContent(previewContent);
      }
    }
  }, [thisUnit, thisProject, contentCollections]);

  const unitType =
    thisUnit?.unitType?.name?.textMap[getCurrentLanguage()] ??
    thisUnit?.unitType?.title;

  const additionalInformation = [
    {
      icon: <InfoIcon icon={['far', 'building']} size="sm" />,
      text: unitType ?? '-'
    }
  ];

  if (showSurface) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'vector-square']} size="sm" />,
      text: `${localizer.unitDetailPage.additionalInformation.unitsSurface}: ${thisUnit?.unitMetadata?.surface}m²`
    });
  }

  if (showBedrooms) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'bed']} size="sm" />,
      text: `${localizer.unitDetailPage.additionalInformation.bedrooms}: ${thisUnit?.unitMetadata?.numberOfBedrooms}`
    });
  }

  if (showBathrooms) {
    additionalInformation.push({
      icon: <InfoIcon icon={['far', 'bath']} size="sm" />,
      text: `${localizer.unitDetailPage.additionalInformation.bathrooms}: ${thisUnit?.unitMetadata?.numberOfBathrooms}`
    });
  }

  // UNIT TITLE
  const unitTitle =
    thisUnit?.localizedTitle?.textMap[getCurrentLanguage()] ??
    thisUnit?.localizedTitle?.textMap?.en ??
    thisUnit?.title;
  document.title = unitTitle || 'Prompto';

  // UNIT PRICE
  const currencySymbol = currencySymbolMap('EUR');
  const unitPrice = `${currencySymbol} ${numeral(
    thisUnit?.unitPrice?.price
  ).format()}`;

  const unitPriceComponent =
    thisUnit?.state === 'SOLD' || !showPrice ? (
      <div />
    ) : /* @ts-ignore */
    thisProject?.settings?.hidePrice ? (
      requestUnitPriceButton
    ) : (
      <PriceBadge
        color={BadgeColor.beige}
        information={unitPrice}
        fontSize={1.875}
        onClick={() => {
          /* @ts-ignore */
          if (thisProject?.settings?.hidePrice) {
            requestUnitPrice();
          }
        }}
      />
    );

  // Some arbitrary logic to select "similar" units
  const similarUnits = unitList.filter((unit: IUnit) => {
    // Don't inlcude this unit ofc
    if (unit.objectId === thisUnit?.objectId) return false;

    if (thisUnit && thisUnit?.unitMetadata && unit.unitMetadata) {
      const bathroomDiff =
        unit.unitMetadata.numberOfBathrooms -
        thisUnit.unitMetadata.numberOfBathrooms;

      const bedroomDiff =
        unit.unitMetadata.numberOfBedrooms -
        thisUnit.unitMetadata.numberOfBedrooms;

      const surfaceDiff =
        unit.unitMetadata.surface - thisUnit.unitMetadata.surface;

      const priceDiff = unit?.unitPrice?.price - thisUnit?.unitPrice?.price;

      // As mentioned, some arbitrary values for now to simulate units that are in range of the criteria of this unit.
      if (bathroomDiff >= -1 && bathroomDiff <= 1) {
        if (bedroomDiff >= -1 && bathroomDiff <= 1) {
          if (surfaceDiff >= -25 && bathroomDiff <= 25) {
            // if no price provided -> priceDiff === NaN - treat as similar
            // if prices are hidden, treat as similar
            if (
              /* @ts-ignore */
              thisProject?.settings?.hidePrice ||
              isNaN(priceDiff) ||
              (priceDiff >= -25000 && priceDiff <= 25000)
            ) {
              return true;
            }
          }
        }
      }

      return false;
    }
  });

  useEffect(() => {
    if (initiateTransferToProject) {
      DataStateDispatch({
        type: 'storeCurrentUnitId',
        payload: ''
      });
    }
  }, [initiateTransferToProject]);

  useEffect(() => {
    if (unitId) {
      DataStateDispatch({
        type: 'storeCurrentUnitId',
        payload: unitId
      });
    }
  }, [unitId]);

  const { isLandscape } = useMobileOrientation();
  const exitAnimation = {
    top: '100%'
  };

  const trackingData: TrackGetInTouchBasicParams = {
    visitorId,
    sessionId,
    projectObjectId: projectId as string,
    vaultObjectId: vaultId as string,
    unitObjectId: unitId
  };

  return (
    <>
      {isModal && (
        <BackdropOverlay
          isModal={isModal}
          onClick={() => {
            setInitiateTransferToProject(true);
          }}
        />
      )}
      <TopBarWrapper
        isModal={isModal}
        initial={isModal ? { top: '100%' } : ''}
        animate={
          isModal && !initiateTransferToProject
            ? { top: `${navBarHeight + 1}px` }
            : exitAnimation
        }
        exit={exitAnimation}
        transition={{ duration: 0.3, ease: 'easeOut' }}
      >
        <GoBackButton
          onClick={() => {
            setInitiateTransferToProject(true);
          }}
        >
          <BackIcon icon={['far', 'arrow-left']} size="sm" />
          <BackText>{localizer.unitDetailPage.backToProject}</BackText>
        </GoBackButton>
        <HeaderButtonsWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <ShareButton
            projectTitle={projectTitle}
            unitTitle={unitTitle as string}
          />
          {!isMobileOnly && (
            <ContactUsButton
              location="navigationBarUnitPage"
              trackingData={trackingData}
              currentUnitId={unitId}
            />
          )}
        </HeaderButtonsWrapper>
      </TopBarWrapper>
      <PageWrapper
        id="unitPage"
        isModal={isModal}
        initial={isModal ? { top: '100%' } : ''}
        animate={
          isModal && !initiateTransferToProject
            ? { top: `${navBarHeight + backToProjectBarHeight}px` }
            : exitAnimation
        }
        exit={exitAnimation}
        onAnimationComplete={() => {
          if (initiateTransferToProject) {
            returnToProjectPage(scrollToUnitsAfterTransferToProject);
          }
        }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
      >
        <ResourcesWrapper>
          <Resources
            contentItemList={contentItemList}
            previewContent={previewContent}
            fetchingContent={false}
            hideModal
          />
        </ResourcesWrapper>
        <MainUnitInfoSection>
          <UnitTitleAndProject>
            <UnitTitle>{unitTitle}</UnitTitle>
            <ProjectInfo>
              <ProjectIcon icon={['far', 'city']} size="sm" />
              <ProjectName>{projectTitle}</ProjectName>
            </ProjectInfo>
          </UnitTitleAndProject>
          <BadgeWrapper>
            {thisUnit?.state && (
              <StatusBadge
                useUnitStateColor={true}
                state={thisUnit.state}
                information={getLocalizedTextForUnitState(
                  thisUnit.state,
                  thisUnit.unitFlow
                )}
              />
            )}
            {unitPriceComponent}
          </BadgeWrapper>
          <UnitAdditionalInformation items={additionalInformation} />
          {(thisUnit?.localizedDescription || thisUnit?.description) && (
            <UnitDescription>
              <UnitDescriptionLabel>
                {localizer.unitDetailPage.unitDescriptionLabel}
              </UnitDescriptionLabel>
              <UnitDescriptionContent>
                <RichTextRenderer
                  richText={getValueFromLocalized(
                    thisUnit.localizedDescription,
                    thisUnit.description
                  )}
                />
              </UnitDescriptionContent>
            </UnitDescription>
          )}
          <GetInTouchCTA
            logoUri={companyLogoUri}
            unitObjectId={unitId}
            trackingData={basicTrackingData}
            location={'centerUnitPage'}
            marketplaceId={marketplaceId}
            // @ts-ignore
            projectSettings={thisProject?.settings ?? {}}
          />
          {RenderUnitTour && thisUnit && (
            <TourContentWrapper>
              <TourTitle> {localizer.unitDetailPage.tourLabel}</TourTitle>
              <TourContainer isLandscape={isLandscape}>
                <RenderUnitTour unitList={[thisUnit]} isUnitTour={true} />
              </TourContainer>
              <TourOverviewMessage
                onClick={() => {
                  setScrollToUnitsAfterTransferToProject(true);
                  setInitiateTransferToProject(true);
                }}
              >
                {localizer.unitDetailPage.backToTourOverview}
              </TourOverviewMessage>
            </TourContentWrapper>
          )}
        </MainUnitInfoSection>

        <UnitListWrapper>
          <UnitList
            title={localizer.unitDetailPage.similarUnitsFound}
            withFilters={false}
            withSorting={true}
            units={similarUnits}
            renderProjectTour={false}
            // @ts-ignore
            hidePrices={!!thisProject?.settings?.hidePrice}
            vaultId={vaultId}
            projectId={projectId}
          />
        </UnitListWrapper>
      </PageWrapper>
    </>
  );
};

export default UnitDetailPage;
