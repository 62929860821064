import { useState, useRef, useCallback } from 'react';

// Components
import { FullscreenIcon, PlusIcon, MinusIcon } from 'src/resources/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import Loader from 'src/components/Loader';

// Helpers
import { extname } from 'src/helpers/utils';
import { IContentItem } from '@/libs/prompto-api/src';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

// Styling
import styled, { StyledProps } from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DocumentScrollWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const ScrollView = styled.div<StyledProps<{ numPages: number }>>`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: ${({ numPages }) => (numPages > 1 ? 'flex-start' : 'center')};
  padding: 10px 7px;
`;

const Controls = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-flow: column;
  min-height: 100px;
  width: 50px;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.8;
  border: 1px solid white;
  border-radius: 3px;
  margin-top: 15px;
  backdrop-filter: blur(5px);
  cursor: pointer;
`;

const RenderInProgress = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.black};
  top: 0;
  left: 0;
  position: absolute;
`;

// default scale for PDF - 4
// that way to get correct default size needed to multiply it to 0.25
const defaultScale = 0.25;
// step = half of default scale, same as min zoom
const zoomStep = defaultScale / 2;
const minZoom = defaultScale / 2;
// max zoom = ten times more than default scale
const maxZoom = defaultScale * 10;

export interface DocumentContentProps {
  data: IContentItem;
  onLoad?: () => void;
}

/**
 * DocumentContent.defaultProps = {
  data: {},
  onLoad: () => undefined
};
 */
const DocumentContent = ({ data, onLoad }: DocumentContentProps) => {
  const [scale, setScale] = useState(defaultScale);
  const [numPages, setNumPages] = useState(0);
  const [defaultYPosition, setDefaultYPosition] = useState(0);
  const [renderedSuccessfully, setRenderSuccess] = useState(false);
  const [interacted, setInteracted] = useState(false);
  const container = useRef<any>();
  const canvasRef = useRef<any>();

  const name = data.name ?? data.title?.textMap?.en;

  const extension = extname(name).replace('.', '');
  const isPdf = extension === 'pdf';

  const onInteract = () => {
    if (!interacted) {
      setInteracted(true);
    }
  };

  // Rendering
  if (!isPdf) {
    return null;
  }

  const setContentInTheMiddle = useCallback(
    (
      setPositionY: (pos: number, delay: number, animationType: string) => void
    ) => {
      // calc the height of canvas and container
      const canvasHeight = canvasRef.current?.getBoundingClientRect()?.height;
      const containerHeight =
        container.current?.getBoundingClientRect()?.height;
      // find out is canvas height (it might be a few pages (canvases)) less than container height
      const heightDelta = containerHeight - canvasHeight * numPages;
      if (heightDelta > 0) {
        // divide this amount for 2 - and this is correct indent from top
        // same as left in bottom part
        // thus we put all content in the middle of the viewer
        const correctTopIndentation = (heightDelta / 2) as number;
        setPositionY(correctTopIndentation, 0, 'easeOut');
        setDefaultYPosition(correctTopIndentation);
      }
    },
    [numPages]
  );

  return (
    <DocumentWrapper ref={container} onScroll={onInteract}>
      <DocumentScrollWrapper>
        <ScrollView numPages={numPages}>
          <TransformWrapper
            options={{
              maxScale: maxZoom,
              minScale: minZoom,
              centerContent: false,
              limitToBounds: false,
              limitToWrapper: false
            }}
            scale={scale}
            onZoomChange={(data: any) => {
              setScale(data.scale >= minZoom ? data.scale : minZoom);
            }}
          >
            {/* @ts-ignore */}
            {({ setPositionY, setPositionX }) => {
              return (
                <>
                  <TransformComponent>
                    <Document
                      key={data.objectId}
                      file={data.contentUri}
                      onLoadSuccess={({ numPages }: any) => {
                        setNumPages(numPages);
                        if (onLoad) {
                          onLoad();
                        }
                      }}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          canvasRef={canvasRef}
                          pageNumber={index + 1}
                          width={
                            container.current?.getBoundingClientRect().width -
                            15
                          }
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          onRenderSuccess={() => {
                            setContentInTheMiddle(setPositionY);
                            setRenderSuccess(true);
                          }}
                          scale={4} // hardcoded to show the best quality view all the time
                        />
                      ))}
                    </Document>
                  </TransformComponent>
                  {renderedSuccessfully ? null : (
                    <RenderInProgress>
                      <Loader />
                    </RenderInProgress>
                  )}
                  <Controls>
                    <Icon
                      onClick={() => {
                        onInteract();
                        setScale((cur) => Math.min(cur + zoomStep, maxZoom));
                      }}
                    >
                      <PlusIcon />
                    </Icon>
                    <Icon
                      onClick={() => {
                        onInteract();
                        setScale((cur) => Math.max(cur - zoomStep, minZoom));
                      }}
                    >
                      <MinusIcon />
                    </Icon>
                    <Icon
                      onClick={() => {
                        onInteract();
                        setTimeout(() => {
                          setPositionY(defaultYPosition, 0, 'easeOut');
                          setPositionX(0, 0, 'easeOut');
                        }, 100);
                        setScale(defaultScale);
                      }}
                    >
                      <FullscreenIcon />
                    </Icon>
                  </Controls>
                </>
              );
            }}
          </TransformWrapper>
        </ScrollView>
      </DocumentScrollWrapper>
    </DocumentWrapper>
  );
};

export default DocumentContent;
