import React, { useState } from 'react';

// Components
//import ClampLines from 'react-clamp-lines';
import { Tooltip } from 'react-tippy';
import { PdfPlaceholder } from 'src/resources/icons';

// Helpers
import env from 'src/environment';
import { computeCloudinaryThumbnail } from 'src/helpers/utils';
import { isMobile } from 'react-device-detect';
import 'react-tippy/dist/tippy.css';
import { IContentItem } from '@/libs/prompto-api/src';

// Styles
import styled, { StyledProps } from 'styled-components';

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MainMobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div<StyledProps<{ isLargeSize: boolean }>>`
  font-family: open-sans;
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  font-size: ${({ isLargeSize }) => (isLargeSize ? '1.5rem' : '0.75rem')};
  line-height: ${({ isLargeSize }) => (isLargeSize ? '3rem' : '1.5rem')};
  font-weight: normal;
  color: black;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
`;

const NoPreviewWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100% - 6px);
  width: 100%;
`;

const StyledPdfPlaceholder = styled(PdfPlaceholder)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TooltipContent = styled.p`
  width: 100%;
  max-width: none;
  height: auto;
  word-break: break-all;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  ${isMobile &&
  `
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
  `}
`;

const ClampedLinesWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 20px;
  box-sizing: border-box;
  p {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 600;
  }
`;
const RATIO = 0.7; // standart A4 format ratio

export interface DocumentPreviewProps {
  data: IContentItem;
  size?: number;
  isVerticalLayout?: boolean;
  onLoaded?: () => void;
}

/**
 * DocumentPreview.defaultProps = {
  data: {},
  pageWidth: null,
  size: null
};
 */
const DocumentPreview = ({
  data,
  size = 0,
  isVerticalLayout,
  onLoaded
}: DocumentPreviewProps) => {
  const currentEnvironment = env();

  // calculate size
  let height = size;
  let width = height * RATIO;

  if (isVerticalLayout) {
    width = size;
    height = width / RATIO;
  }

  const [thumbnailError, setThumbnailError] = useState(false);

  if (!data) return null;

  const [cloudinaryImageUri] = useState(
    computeCloudinaryThumbnail(
      decodeURIComponent(data.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryImageBaseUrl,
      'h_600,c_fill,dn_50'
    )
  );

  const fileName = data?.name ?? data?.title?.textMap?.en;
  const extension = fileName.split('.').pop();
  const isPdf = extension === 'pdf';

  // Rendering
  if (!isPdf) {
    return null;
  }

  // const linesWrapper = (
  //   <ClampedLinesWrapper>
  //     <p>{data.title.textMap.en}</p>
  //   </ClampedLinesWrapper>
  // );

  let mainContent = (
    <NoPreviewWrapper>
      <StyledPdfPlaceholder />
      <Title isLargeSize={size > 1000}>{data.title.textMap.en}</Title>
    </NoPreviewWrapper>
  );

  if (cloudinaryImageUri && !thumbnailError) {
    mainContent = (
      <ImageWrapper>
        <img
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          src={cloudinaryImageUri}
          alt={data?.title?.textMap?.en ?? ''}
          onError={() => {
            setThumbnailError(true);
          }}
          onLoad={onLoaded}
        />
        {/* @ts-ignore */}
        {/* <StyledTooltip
          html={<TooltipContent>{data.title.textMap.en}</TooltipContent>}
          position="top"
        >
          {linesWrapper}
        </StyledTooltip> */}
      </ImageWrapper>
    );
  } else {
    // in new design placeholder has square ratio
    width = height;
  }

  let contentWrapper = <MainWrapper>{mainContent}</MainWrapper>;
  if (isMobile) {
    contentWrapper = <MainMobileWrapper>{mainContent}</MainMobileWrapper>;
  }

  return contentWrapper;
};

export default DocumentPreview;
