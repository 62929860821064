import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleRight as faAngleRightRegular,
  faArrowRight as faArrowRightRegular,
  faArrowLeft as faArrowLeftRegular,
  faArrowDown as faArrowDownRegular,
  faAward,
  faBabyCarriage,
  faBan,
  faBasketballBall,
  faBath as faBathRegular,
  faBatteryBolt,
  faBed as faBedRegular,
  faBicycle,
  faBuilding,
  faBurgerSoda,
  faBus,
  faCalendarDay,
  faCog,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faClipboardCheck,
  faCoins,
  faCompass as faCompassRegular,
  faCompressAlt,
  faCompressArrowsAlt,
  faCube,
  faExpandAlt,
  faFileDownload,
  faFlag as faFlagRegular,
  faImage as faImageRegular,
  faInfoCircle as faInfoCircleRegular,
  faLevelDown,
  faLevelUp,
  faLongArrowLeft as faLongArrowLeftRegular,
  faLongArrowDown as faLongArrowDownRegular,
  faLongArrowRight as faLongArrowRightRegular,
  faLongArrowUp as faLongArrowUpRegular,
  faMap,
  faMedkit,
  faMinus,
  faMountains as faMountainsRegular,
  faPhotoVideo,
  faPlayCircle,
  faPlus,
  faPrint,
  faRulerCombined,
  faTag,
  faVectorSquare as faVectorSquareRegular,
  faHouse,
  faHouseDay,
  faHomeAlt,
  faHomeLg,
  faGarageOpen,
  faWarehouseAlt,
  faCity,
  faGopuram,
  faHotel,
  faLandmarkAlt,
  faStore,
  faFarm,
  faSchool,
  faWindowFrame,
  faAirConditioner,
  faHouseSignal,
  faBellOn,
  faBlindsRaised,
  faBooks,
  faCoffeePot,
  faDungeon,
  faFireplace,
  faGarageCar,
  faLightbulbOn,
  faMailbox,
  faPaw,
  faDumbbell,
  faSpa,
  faSync,
  faTrees,
  faCctv,
  faDryer,
  faFireExtinguisher,
  faMapSigns,
  faMapMarkerAlt as faMapMarkerAltRegular,
  faBinoculars as faBinocularsRegular,
  faConstruction,
  faGrin,
  faWheelchair,
  faHospital,
  faChurch,
  faMosque,
  faArchway,
  faUniversity,
  faIndustryAlt,
  faCampground,
  faRouteHighway,
  faGraduationCap,
  faMoneyBillWave,
  faWallet,
  faCroissant,
  faCocktail,
  faCoffeeTogo,
  faBeer,
  faUtensilsAlt,
  faAppleCrate,
  faStoreAlt,
  faBagsShopping,
  faWasher,
  faGasPump,
  faChargingStation,
  faCarGarage,
  faCarMechanic,
  faSubway,
  faHelicopter,
  faAnchor,
  faCaravan,
  faTram,
  faFlowerDaffodil,
  faUmbrellaBeach,
  faIslandTropical,
  faWater,
  faSwimmingPool,
  faSunCloud,
  faHiking,
  faDogLeashed,
  faFutbol,
  faRacquet,
  faGolfClub,
  faEye as faEyeRegular,
  faShoppingCart,
  faVideo,
  faFilm,
  faClinicMedical,
  faHeart as faHeartRegular,
  faCommentAltLines,
  faChevronDown as faChevronDownRegular,
  faChevronUp as faChevronUpRegular,
  faFilter as faFilterRegular,
  faTimes as faTimesRegular,
  faExpand as faExpandRegular,
  faGlobe as faGlobeRegular,
  faPortalEnter,
  faShareAlt as faShareAltRegular,
  faTimesCircle as faTimesCircleRegular,
  faSearch as faSearchRegular,
  faFileSearch,
  faBars as faBarsRegular,
  faSort,
  faSpinner as faSpinnerRegular,
  faCouch,
  faBedAlt,
  faOven,
  faShower,
  faChairOffice,
  faTreeAlt,
  faSeedling,
  faTelescope,
  faSortCircleUp,
  faSortCircleDown,
  faFaucet,
  faStroopwafel,
  faSolarPanel,
  faTv,
  faRulerVertical,
  faRulerHorizontal,
  faDollyFlatbed,
  faForklift,
  faPalletAlt,
  faConveyorBelt,
  faCalendarAlt,
  faFileContract,
  faFire,
  faPlug,
  faBolt,
  faWifi,
  faDumpster,
  faTruck,
  faWeightHanging,
  faParking,
  faCarSide,
  faWrench,
  faHammer,
  faUtensils,
  faPaintRoller,
  faTags,
  faWindTurbine,
  faRouter,
  faPlaneAlt,
  faInventory,
  faCompress as faCompressRegular,
  faPencil
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBinoculars,
  faCaretDown,
  faCaretUp,
  faChevronDown,
  faCircle as faCircleSolid,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faFilter,
  faFlag,
  faHeart as faHeartSolid,
  faHome as faHomeSolid,
  faImage,
  faLayerGroup as faLayerGroupSolid,
  faList,
  faMale,
  faMapMarkerAlt,
  faMapPin,
  faMoon,
  faShoePrints,
  faSortDown,
  faSortUp,
  faStreetView as faStreetViewSolid,
  faSun,
  faTimesCircle as faTimesCircleSolid,
  faWalking as faWalkingSolid,
  faBuilding as faBuildingSolid,
  faTag as faTagSolid,
  faUserCircle as faUserCircleSolid
} from '@fortawesome/free-solid-svg-icons';

import { faSignIn } from '@fortawesome/pro-solid-svg-icons';

import { faElementor } from '@fortawesome/free-brands-svg-icons';

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowToLeft,
  faArrowToRight,
  faArrowAltSquareRight,
  faBars,
  faBath,
  faBed,
  faBringFront,
  faBuilding as faBuildingLight,
  faCheckCircle,
  faChevronDoubleLeft as faChevronDoubleLeftLight,
  faCity as faCityLight,
  faClone,
  faCompass,
  faCompress,
  faCube as faCubeLight,
  faEllipsisH,
  faEllipsisHAlt,
  faEnvelope,
  faEnvelopeOpenText,
  faExpand,
  faFilter as faFilterLight,
  faFlag as faFlagLight,
  faFolder,
  faGlobe,
  faGripHorizontal,
  faHeart,
  faHome,
  faImage as faImageLight,
  faInfoCircle,
  faLayerGroup,
  faLevelDown as faLevelDownLight,
  faLongArrowLeft,
  faLongArrowRight,
  faMagic,
  faMap as faMapLight,
  faMapMarkerAltSlash,
  faMountains,
  faPen,
  faPhoneAlt,
  faSearch,
  faShare,
  faShareAlt,
  faSparkles,
  faSpinner,
  faStars,
  faStreetView,
  faTag as faTagLight,
  faTimes,
  faTimesCircle,
  faTrash,
  faTvAlt,
  faUserCircle,
  faUserPlus,
  faVectorSquare,
  faVolumeSlash,
  faVolumeUp,
  faWalking,
  faCommentAltCheck,
  faExclamationTriangle
} from '@fortawesome/pro-light-svg-icons';

export default function registerIcons() {
  library.add(
    //@ts-ignore
    faPencil,
    faUserCircleSolid,
    faSignIn,
    faPortalEnter,
    faGlobeRegular,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleRightRegular,
    faArrowLeft,
    faArrowRight,
    faArrowToLeft,
    faArrowToRight,
    faArrowRightRegular,
    faArrowAltSquareRight,
    faArrowDownRegular,
    faAward,
    faBabyCarriage,
    faBan,
    faBars,
    faBasketballBall,
    faBath,
    faBathRegular,
    faBatteryBolt,
    faBed,
    faBedRegular,
    faBicycle,
    faBinoculars,
    faBringFront,
    faBuilding,
    faBuildingLight,
    faBurgerSoda,
    faBus,
    faCalendarDay,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronDownRegular,
    faChevronLeft,
    faChevronRight,
    faChevronDoubleLeft,
    faChevronDoubleLeftLight,
    faChevronDoubleRight,
    faCircleSolid,
    faCityLight,
    faClipboardCheck,
    faClone,
    faCog,
    faCoins,
    faCompass,
    faCompassRegular,
    faCompress,
    faCompressAlt,
    faCompressArrowsAlt,
    faCompressRegular,
    faCube,
    faCubeLight,
    faElementor,
    faEllipsisH,
    faEllipsisHAlt,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpenText,
    faExpand,
    faExpandAlt,
    faEye,
    faEyeSlash,
    faFileDownload,
    faFilter,
    faFilterLight,
    faFilterRegular,
    faFlag,
    faFlagLight,
    faFlagRegular,
    faFolder,
    faGlobe,
    faGripHorizontal,
    faHeart,
    faHeartSolid,
    faHome,
    faHomeSolid,
    faImage,
    faImageLight,
    faImageRegular,
    faInfoCircle,
    faInfoCircleRegular,
    faLayerGroup,
    faLayerGroupSolid,
    faLevelDown,
    faLevelDownLight,
    faLevelUp,
    faList,
    faLongArrowDownRegular,
    faLongArrowLeft,
    faArrowLeftRegular,
    faLongArrowLeftRegular,
    faLongArrowRight,
    faLongArrowRightRegular,
    faLongArrowUpRegular,
    faMagic,
    faMale,
    faMap,
    faMapLight,
    faMapMarkerAlt,
    faMapMarkerAltSlash,
    faMapPin,
    faMedkit,
    faMinus,
    faMoon,
    faMountains,
    faMountainsRegular,
    faPen,
    faPhoneAlt,
    faPhotoVideo,
    faPrint,
    faPlayCircle,
    faPlus,
    faRulerCombined,
    faSearch,
    faSearchRegular,
    faShare,
    faShareAlt,
    faShoePrints,
    faSort,
    faSortDown,
    faSortUp,
    faSparkles,
    faSpinner,
    faStars,
    faStreetView,
    faStreetViewSolid,
    faSun,
    faSync,
    faTag,
    faTagLight,
    faTimes,
    faTimesCircle,
    faTimesCircleSolid,
    faTimesRegular,
    faTrash,
    faTvAlt,
    faVectorSquare,
    faVectorSquareRegular,
    faVolumeSlash,
    faVolumeUp,
    faWalking,
    faWalkingSolid,
    faHouse,
    faHouseDay,
    faHomeAlt,
    faHomeLg,
    faGarageOpen,
    faWarehouseAlt,
    faCity,
    faGopuram,
    faHotel,
    faLandmarkAlt,
    faStore,
    faFarm,
    faSchool,
    faWindowFrame,
    faAirConditioner,
    faHouseSignal,
    faBellOn,
    faBlindsRaised,
    faBooks,
    faCoffeePot,
    faDungeon,
    faFireplace,
    faGarageCar,
    faLightbulbOn,
    faMailbox,
    faPaw,
    faDumbbell,
    faSpa,
    faTrees,
    faCctv,
    faDryer,
    faBuildingSolid,
    faFireExtinguisher,
    faMapSigns,
    faMapMarkerAltRegular,
    faBinocularsRegular,
    faConstruction,
    faGrin,
    faWheelchair,
    faHospital,
    faChurch,
    faMosque,
    faArchway,
    faUniversity,
    faIndustryAlt,
    faCampground,
    faRouteHighway,
    faGraduationCap,
    faMoneyBillWave,
    faWallet,
    faCroissant,
    faCocktail,
    faCoffeeTogo,
    faBeer,
    faUtensilsAlt,
    faAppleCrate,
    faStoreAlt,
    faBagsShopping,
    faWasher,
    faGasPump,
    faChargingStation,
    faCarGarage,
    faCarMechanic,
    faSubway,
    faHelicopter,
    faAnchor,
    faCaravan,
    faTram,
    faFlowerDaffodil,
    faUmbrellaBeach,
    faIslandTropical,
    faWater,
    faSwimmingPool,
    faSunCloud,
    faHiking,
    faDogLeashed,
    faFutbol,
    faRacquet,
    faGolfClub,
    faEyeRegular,
    faShoppingCart,
    faVideo,
    faFilm,
    faClinicMedical,
    faUserPlus,
    faUserCircle,
    faHeartRegular,
    faCommentAltLines,
    faTagSolid,
    faExpandRegular,
    faCommentAltCheck,
    faExclamationTriangle,
    faChevronUpRegular,
    faShareAltRegular,
    faTimesCircleRegular,
    faSearchRegular,
    faFileSearch,
    faBarsRegular,
    faSpinnerRegular,
    faCouch,
    faBedAlt,
    faOven,
    faShower,
    faChairOffice,
    faTreeAlt,
    faSeedling,
    faTelescope,
    faSortCircleUp,
    faSortCircleDown,
    faFaucet,
    faStroopwafel,
    faSolarPanel,
    faTv,
    faRulerVertical,
    faRulerHorizontal,
    faDollyFlatbed,
    faForklift,
    faPalletAlt,
    faConveyorBelt,
    faCalendarAlt,
    faFileContract,
    faFire,
    faPlug,
    faBolt,
    faWifi,
    faDumpster,
    faTruck,
    faWeightHanging,
    faParking,
    faCarSide,
    faWrench,
    faHammer,
    faUtensils,
    faPaintRoller,
    faTags,
    faWindTurbine,
    faRouter,
    faPlaneAlt,
    faInventory
  );
}
