import React, { ReactNode, useEffect, useRef, useState } from 'react';

// Components
import ProjectCard from './ProjectCard';
import MotionSlider from 'src/components/motionSlider/MotionSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { IProject } from '@/libs/prompto-api/src';
import useIntersectionObserver from 'src/hooks/use-intersection-observer';
import { motion } from 'framer-motion';
import ReactResizeDetector from 'react-resize-detector';
import { useNavigate } from 'react-router-dom';
import localizer from 'src/localization/localizer';

// Styling
import styled, { StyledProps, keyframes } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';

const Slider = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
`;

const CardWrapper = styled.div<StyledProps<{ width: number }>>`
  width: 100%;
  height: 400px;
  flex-shrink: 0;
`;

const Wrapper = styled(motion.div)<StyledProps<{ isWhite?: boolean }>>`
  width: 100%;
  padding: 0 16px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme, isWhite }) =>
    isWhite ? theme.white : 'transparent'};
  ${forBiggerThan.mobile`
    padding: 0 64px 64px;
  `}
`;

const ProjectCardPlaceholder = styled.div`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  height: calc(100% - 8px);
  background-color: ${({ theme }) => theme.white};
  opacity: 0.4;
`;

const AllProjectsButton = styled.div`
  font-weight: normal;
  font-size: 1.25rem;
  //border-bottom: 1px solid black;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${forBiggerThan.mobile`
  margin-top: 20px;
  padding-bottom: 3px;
  font-size: 1.75rem;
  align-self: flex-start;

`}
`;

const arrowRight = keyframes`
  0% {
    opacity:1;
    transform:translateX(0)
  }
  40% {
    transform:translateX(20px)
  }
  70% {
    opacity:1
  }
  90% {
    opacity:0
  }
  100% {
    opacity:0;
    transform:translateX(20px)
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 15px;
  animation: ${arrowRight} 2s infinite;
`;

interface ProjectsSliderProps {
  loading: boolean;
  projects: IProject[];
  activeItemsAmount?: number;
  heading: ReactNode;
  hideBadge?: boolean;
  setBackgroundToWhite?: boolean;
  // Defines how many slides should be displayed for a certain screen width.
  // Screen width breakpoints are: [768, 920, 1200, 1440, 1800].
  // By default 1 slide should be displayed.
  slidesPerScreen?: number[];
  // Enable auto loop, disabled by default
  autoLoop?: boolean;
  // Loop duration in seconds, applicable only if auto loop is enabled
  loopDuration?: number;
}

const ProjectsSlider = ({
  loading,
  projects,
  heading,
  hideBadge,
  setBackgroundToWhite,
  slidesPerScreen = [1, 1, 1, 1, 1],
  autoLoop = false,
  loopDuration = 0
}: ProjectsSliderProps) => {
  const cardWidth = window.innerWidth - 40;
  const [isDragging, setIsDragging] = useState(false);
  const [activeItemsAmount, setActiveItemsAmount] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [localProjects, setLocalProjects] = useState<IProject[]>([]);
  const ref = useRef(null);
  const { gotVisible } = useIntersectionObserver(ref);
  const navigate = useNavigate();

  useEffect(() => {
    const breakPoints = [768, 920, 1200, 1440, 1800];
    let activeItemsAmount = 1;
    breakPoints.forEach((point, idx) => {
      if (screenWidth > point) {
        activeItemsAmount = slidesPerScreen[idx];
      }
    });
    setActiveItemsAmount(activeItemsAmount);
  }, [screenWidth, slidesPerScreen]);

  // only projects with thumbnails should be displayed
  // generally project could not be created without thumbnail
  // just do a double check
  useEffect(() => {
    if (projects.length === 0 || localProjects.length) return;
    const projectsWithThumbnails = [];
    for (let idx = 0; projectsWithThumbnails.length < 5; idx++) {
      const project = projects[idx];
      if (!project) {
        // if projects with thumbnails less than 5 - do break
        break;
      }
      if (project?.projectThumbnailUri) {
        projectsWithThumbnails.push(project);
      }
    }
    setLocalProjects(projectsWithThumbnails);
  }, [projects]);

  const cards = loading
    ? [...Array(5)].map((_, idx) => (
        <CardWrapper key={idx} width={cardWidth}>
          <ProjectCardPlaceholder />
        </CardWrapper>
      ))
    : localProjects.map((project, i) => (
        <CardWrapper key={project.projectId} width={cardWidth}>
          <ProjectCard
            idx={i}
            project={project}
            size={400}
            hideBadge={hideBadge}
            isDragging={isDragging}
          />
        </CardWrapper>
      ));

  return (
    <Wrapper
      ref={ref}
      animate={{ opacity: gotVisible ? 1 : 0, y: gotVisible ? 0 : 100 }}
      transition={{ ease: 'linear', duration: 0.3 }}
      isWhite={setBackgroundToWhite}
    >
      <Slider>
        <MotionSlider
          padding={screenWidth <= 768 ? 16 : 64}
          rightPadding={screenWidth <= 768 ? 0 : 180}
          gap={screenWidth <= 768 ? 12 : 32}
          loadDataEndReached={true}
          maxActiveItems={activeItemsAmount || 1}
          minActiveItems={activeItemsAmount || 1}
          disableScroll={false}
          velocity={0.5}
          enableLockedScroll={true}
          onScroll={() => undefined}
          minItemWidth={0}
          heading={heading}
          setIsDragging={setIsDragging}
          autoLoop={autoLoop}
          loopDuration={loopDuration}
        >
          {cards}
        </MotionSlider>
      </Slider>
      <AllProjectsButton
        onClick={() => {
          navigate(`/projects` + window.location.search);
        }}
      >
        {localizer.homePage.viewAllprojects}
        <ArrowIcon icon={['far', 'arrow-right']} size="sm" />
      </AllProjectsButton>
      <ReactResizeDetector
        handleWidth
        skipOnMount
        onResize={() => {
          setScreenWidth(window.innerWidth);
        }}
      />
    </Wrapper>
  );
};

export default ProjectsSlider;
