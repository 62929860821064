import { useRef, useEffect, forwardRef, useState, useCallback } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterMenuContent from './FilterMenuContent';

// Helpers
import localizer from 'src/localization/localizer';
import { capitalize } from 'src/helpers/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { useProjectsFiltersState } from 'src/store/ProjectsFiltersStore';

// Types
import {
  ProjectsFilterKeyType,
  IProjectFilter,
  ISelectedProjectFilter
} from '../ProjectsFilters';

// Styling
import styled, { StyledProps } from 'styled-components';
import { forBiggerThan } from 'src/helpers/ui';
import { isMobileOnly } from 'react-device-detect';

const Wrapper = styled(motion.div)<StyledProps<{ alignToLeft?: boolean }>>`
  padding: 0 16px 0 0;
  position: relative;

  ${({ alignToLeft }) => forBiggerThan.mobile`
    padding: ${alignToLeft ? '0 32px 0 0' : ' 16px'};
  `}
`;

const StyledButton = styled.button`
  position: relative;
  height: 48px;
  padding: 5px 25px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.beigeBg20};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.beigeBg25};
  }
`;

const SelectedFiltersCount = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: bold;
`;

const StyledFilterIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const Menu = styled.div`
  width: 100%;
  padding: 8px 0;
  color: ${({ theme }) => theme.black};
`;

const MenuTitle = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 16px;
  box-sizing: border-box;
`;

const MenuOption = styled(MenuTitle)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.gray5};
  }
`;

const MenuOptionIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  color: ${({ theme }) => theme.gray50};
  margin-right: 12px;
`;

const NoMoreFilters = styled(MenuTitle)`
  color: ${({ theme }) => theme.gray50};
`;

const MenuWrapper = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 56px;
  left: 16px;
  width: 320px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.white};
  max-height: 320px;
  overflow: hidden;
  overflow-y: auto;
`;

const MobileMenuWrapper = styled(motion.div)`
  position: fixed;
  z-index: 3008;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.beigeBg10};
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ApplyFiltersBlock = styled(motion.div)`
  position: sticky;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
  flex-shrink: 0;
  margin: auto 0 0;
  padding: 14px 16px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg10};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MobileClearAllButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.beigeBg10};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
`;

const MobileApplyFilterButton = styled.button`
  position: static;
  right: 0;
  top: 18px;
  min-width: 120px;
  width: fit-content;
  align-self: center;
  height: 44px;
  padding: 5px 15px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  font-size: 0.875rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MobileFiltersTitle = styled.div`
  height: 72px;
  padding: 10px 16px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.beigeBg10};
  color: ${({ theme }) => theme.black};
  font-size: 1.25rem;
`;

const CloseFiltersButton = styled.button`
  width: 44px;
  height: 44px;
  margin: 0 0 0 16px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.gray20};
  background-color: ${({ theme }) => theme.beigeBg10};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface AddFiltersButtonProps {
  mode: 'list' | 'filtersManager';
  isFilterEmpty: boolean;
  availableFilters: IProjectFilter[];
  onFilterSelected: (filterKey: ProjectsFilterKeyType) => void;
  selectedFilters: ISelectedProjectFilter[];
  removeFilter?: (filterKey: ProjectsFilterKeyType) => void;
  onClearAll?: () => void;
  onApplyFilters?: () => void;
  alignToLeft?: boolean;
}

const AddFiltersButton = forwardRef(
  (
    {
      mode,
      isFilterEmpty,
      availableFilters,
      onFilterSelected,
      selectedFilters,
      removeFilter,
      onClearAll,
      onApplyFilters,
      alignToLeft
    }: AddFiltersButtonProps,
    ref: any
  ) => {
    const [showMenu, setShowMenu] = useState(
      mode === 'filtersManager' && !isMobileOnly
    );
    const [hovered, setHovered] = useState(
      mode === 'filtersManager' && !isMobileOnly
    );

    // In mobile we save the initial filters state,
    // so that when filters modal is closed we reset filters state
    // to its initial value
    const [initialFiltersState, setInitialFiltersState] = useState<any>();

    // Projects filters state
    const { ProjectsFiltersState, ProjectsFiltersDispatch } =
      useProjectsFiltersState();
    const { temporaryFilterValues, selectedFilters: initialSelectedFilters } =
      ProjectsFiltersState;

    const localized = localizer.projectsFilters as any;

    const title = isFilterEmpty ? localized.addFilters : localized.filters;

    const hoverTimer = useRef<any>();

    const showSelectedFilters =
      mode === 'filtersManager' && selectedFilters.length > 0;

    useEffect(() => {
      if (showMenu && isMobileOnly) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [showMenu]);

    useEffect(() => {
      if (!hovered) {
        hoverTimer.current = setTimeout(() => {
          setShowMenu(false);
        }, 500);
      } else {
        clearTimeout(hoverTimer.current);
      }
    }, [hovered]);

    const applyFilters = useCallback(() => {
      onApplyFilters && onApplyFilters();
      setShowMenu(false);
    }, [onApplyFilters]);

    const clearFilters = useCallback(() => {
      onClearAll && onClearAll();
      setShowMenu(false);
    }, [onClearAll]);

    const closeMobileFilters = useCallback(() => {
      setShowMenu(false);
      ProjectsFiltersDispatch({
        type: 'onCloseMobileFiltersModal',
        payload: initialFiltersState
      });
    }, [ProjectsFiltersDispatch, initialFiltersState]);

    const toggleFiltersMenu = useCallback(() => {
      if (isMobileOnly) {
        setShowMenu(true);
        setInitialFiltersState({
          selectedFilters: initialSelectedFilters,
          availableFilters,
          temporaryFilterValues
        });
      } else {
        setShowMenu((prev: boolean) => !prev);
      }
    }, [initialSelectedFilters, availableFilters, temporaryFilterValues]);

    const menu = (
      <Menu>
        <>
          {showSelectedFilters
            ? selectedFilters.map((filter) => (
                <FilterMenuContent
                  key={filter.key}
                  filterKey={filter.key}
                  displayMode="column"
                  removeFilter={removeFilter}
                />
              ))
            : null}
          {availableFilters.length > 0 ? (
            <>
              {!isMobileOnly && (
                <MenuTitle>{localized.addFiltersYouNeed}</MenuTitle>
              )}
              {availableFilters.map((filter: IProjectFilter) => (
                <MenuOption
                  key={filter.key}
                  onClick={() => {
                    onFilterSelected(filter.key);
                    if (mode === 'list') {
                      setShowMenu(false);
                    }
                  }}
                >
                  <MenuOptionIcon
                    size="1x"
                    icon={['far', filter.icon as any]}
                  />
                  {capitalize(localized[filter.label])}
                </MenuOption>
              ))}
            </>
          ) : (
            <NoMoreFilters>{localized.allFiltersApplied}</NoMoreFilters>
          )}
        </>
      </Menu>
    );

    return (
      <Wrapper
        ref={ref}
        onHoverStart={() => {
          setHovered(true);
        }}
        onHoverEnd={() => {
          setHovered(false);
        }}
        alignToLeft={alignToLeft}
      >
        <StyledButton onClick={toggleFiltersMenu}>
          <StyledFilterIcon icon={['far', 'filter']} size="1x" />
          {title}
          {showSelectedFilters && (
            <SelectedFiltersCount>
              {selectedFilters.length}
            </SelectedFiltersCount>
          )}
        </StyledButton>

        <AnimatePresence>
          {showMenu &&
            (isMobileOnly ? (
              <MobileMenuWrapper {...transitions}>
                <MobileFiltersTitle>
                  {localized.addFiltersYouNeed}
                  <CloseFiltersButton onClick={closeMobileFilters}>
                    <FontAwesomeIcon icon={['far', 'times']} size="lg" />
                  </CloseFiltersButton>
                </MobileFiltersTitle>

                {menu}

                <ApplyFiltersBlock>
                  <MobileClearAllButton onClick={clearFilters}>
                    {localizer.unitListComponent.clearAll}
                  </MobileClearAllButton>
                  <MobileApplyFilterButton onClick={applyFilters}>
                    {localizer.unitListComponent.applyFilters}
                  </MobileApplyFilterButton>
                </ApplyFiltersBlock>
              </MobileMenuWrapper>
            ) : (
              <MenuWrapper {...transitions}>{menu}</MenuWrapper>
            ))}
        </AnimatePresence>
      </Wrapper>
    );
  }
);

const transitions = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
  transition: { duration: 0.12 }
};

export default AddFiltersButton;
