import { useEffect } from 'react';

// Components
import UnitStateBadge from './UnitStateBadge';
import UnitPreview from 'src/components/unitList/UnitPreview';

// Helpers
import currencySymbolMap from 'currency-symbol-map';
import localizer from 'src/localization/localizer';
import { motion } from 'framer-motion';
import { capitalize, displayLocalizedValue } from 'src/helpers/utils';
import { checkUnitFieldsVisibility, getUnitPrice } from 'src/helpers/vmUnit';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { StyledProps } from 'styled-components';
import { GeneralFieldType } from '@/libs/prompto-api/src';

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const UnitCardWrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 330px;
  max-height: calc(100% - 30px);
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 21px;
`;

const UnitCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${(props) => props.theme.gray20};
  background-color: ${(props) => props.theme.white};
`;

const UnitInfo = styled.div`
  font-size: 0.875rem;
  color: black;
`;

const UnitTitle = styled(UnitInfo)`
  font-size: 1.5rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  word-break: break-word;
  flex-shrink: 0;
`;

const UnitPriceStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

const UnitPrice = styled(UnitInfo)`
  font-weight: 600;
  color: ${(props) => props.theme.gray50};
  background: ${(props) => props.theme.beigeBg20};
  height: 28px;
  padding: 4px 12px;
  box-sizing: border-box;
  border-radius: 14px;
  display: flex;
  align-items: center;
`;

const Price = styled(UnitInfo)`
  font-size: 1rem;
  font-weight: 700;
  color: inherit;
  color: ${(props) => props.theme.black};
`;

const BlockWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.white};
`;

const MainInfoBlockWrapper = styled(BlockWrapper)`
  align-items: center;
  padding: 24px 20px 15px;
`;

const UnitMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  justify-content: center;
  gap: 2px;
`;

const UnitTypeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const UnitType = styled.div`
  height: 20px
  font-size: 0.875rem;
  color: ${(props) => props.theme.black};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-word;
  flex-shrink: 0;
`;

const StyledPriceIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 5px;
  color: ${(props) => props.theme.gray50};
`;

const StyledUnitTypeIcon = styled(StyledPriceIcon)``;

const ExploreDetailsButton = styled.button`
  height: 70px;
  width: 100%;
  padding: 10px;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 ${(props) => props.theme.gray20};
  background-color: ${(props) => props.theme.gray10};
  color: ${(props) => props.theme.black};
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  flex-shrink: 0;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: ${(props) => props.theme.black};
  margin-left: 11px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: ${(props) => props.theme.gray50};
  padding: 0;
`;

const UnitStateTag = styled(UnitStateBadge)`
  position: absolute;
  left: 10px;
  top: -15px;
  font-size: 0.75rem;
  color: ${(props) => props.theme.white};
  border-radius: 20px;
  min-width: 5rem;
  height: 26px;
`;

const GeneralFieldsBlockWrapper = styled(BlockWrapper)`
  justify-content: center;
  padding: 0;
`;

const UnitMetaInfoWrapper = styled.div<StyledProps<{ flexBasis: number }>>`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => props.flexBasis}%;
`;

const UnitMetaInfo = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: auto;
  white-space: nowrap;
`;

const UnitMetaIcon = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  margin-right: 8px;
  color: ${(props) => props.theme.gray50};
`;

const Divider = styled.div`
  width: 1px;
  height: 12px;
  margin: 0 8px;
  background-color: ${(props) => props.theme.gray20};
  flex-shrink: 0;
`;

// Types
interface MobileUnitPreviewProps {
  unit: any;
  onClose: () => void;
  onExploreDetailsClick: (unit: any) => void;
  hidePrices: boolean;
}

const MobileUnitPreview = ({
  unit,
  onClose,
  onExploreDetailsClick,
  hidePrices
}: MobileUnitPreviewProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
  }, []);

  const closeUnitPreview = () => {
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
    onClose();
  };

  const currencySymbol = currencySymbolMap(
    unit.unitPrice?.currencyCode ?? 'EUR'
  );
  const unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;

  const [showPrice, showBedrooms, showBathrooms, showSurface] =
    checkUnitFieldsVisibility(unit, [
      GeneralFieldType.price,
      GeneralFieldType.numberOfBedrooms,
      GeneralFieldType.numberOfBathrooms,
      GeneralFieldType.surface
    ]);

  const renderMetaInfo = () => {
    const metaInfo: { icon: [IconPrefix, IconName]; info: any }[] = [];

    if (showSurface) {
      metaInfo.push({
        icon: ['far', 'vector-square'],
        info: unit.unitMetadata?.surface
          ? `${unit.unitMetadata?.surface} m²`
          : '-'
      });
    }

    if (showBedrooms) {
      metaInfo.push({
        icon: ['far', 'bed'],
        info: unit.unitMetadata?.numberOfBedrooms ?? '-'
      });
    }

    if (showBathrooms) {
      metaInfo.push({
        icon: ['far', 'bath'],
        info: unit.unitMetadata?.numberOfBathrooms ?? '-'
      });
    }

    const flexBasis = 100 / metaInfo.length;
    return metaInfo.map((item, idx) => {
      const needDivider = metaInfo.length > 1 && idx !== 0;
      return (
        <UnitMetaInfoWrapper key={idx} flexBasis={flexBasis}>
          {needDivider && <Divider />}
          <UnitMetaInfo>
            <UnitMetaIcon icon={item.icon as any} size="1x" />
            <UnitInfo>{item.info}</UnitInfo>
          </UnitMetaInfo>
        </UnitMetaInfoWrapper>
      );
    });
  };

  return (
    <Backdrop
      onClick={closeUnitPreview}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <UnitCardWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <UnitCard>
          <UnitStateTag unit={unit} />

          <CloseButton onClick={closeUnitPreview}>
            <CloseIcon icon={['fal', 'times']} size="1x" />
          </CloseButton>

          <MainInfoBlockWrapper>
            <UnitPreview unit={unit} height={92} />

            <UnitMainInfo>
              <UnitTitle>{unit?.title}</UnitTitle>
              <UnitTypeAndPrice>
                {unit?.unitType && (
                  <UnitType>
                    {unit?.unitType?.icon && (
                      <StyledUnitTypeIcon
                        icon={['far', unit.unitType.icon]}
                        size="1x"
                      />
                    )}
                    {displayLocalizedValue(unit?.unitType?.name?.textMap)}
                  </UnitType>
                )}

                {!hidePrices && unit.state !== 'SOLD' && showPrice && (
                  <UnitPriceStatus>
                    <UnitPrice>
                      {/* <StyledPriceIcon icon={['far', 'tag']} size="1x" /> */}
                      <Price>{unitPrice}</Price>
                    </UnitPrice>
                  </UnitPriceStatus>
                )}
              </UnitTypeAndPrice>

              <GeneralFieldsBlockWrapper>
                {renderMetaInfo()}
              </GeneralFieldsBlockWrapper>
            </UnitMainInfo>
          </MainInfoBlockWrapper>

          <ExploreDetailsButton
            onClick={() => {
              onExploreDetailsClick({ unitItem: unit });
              closeUnitPreview();
            }}
          >
            {capitalize(localizer.projectTour.exploreDetails)}
            <ArrowIcon icon={['fal', 'arrow-right']} size="1x" />
          </ExploreDetailsButton>
        </UnitCard>
      </UnitCardWrapper>
    </Backdrop>
  );
};

export default MobileUnitPreview;
